import { Link } from "gatsby"
import React from "react"
import Meta from "../utilities/seo"
import styled from 'styled-components'

const Wrap = styled.div`
  padding: 0.75rem;
  padding-top: 0; 

  a {
    display: block;
    padding: 0.5rem 0;
    color: ${props => props.theme.primary};
  }

  span {
    color: ${props => props.theme.primary};
  }

  h1 {
    line-height: 1.1;
    padding-bottom: 2rem;
  }
`

export default () => (
  <>
    <Meta title="404" />
    <Wrap>
      <h1>4-<span>wow</span>-4. Sorry page not found :(</h1>

      <Link to="/">work</Link>
      <Link to="/agency">agency</Link>
      <Link to="/team">team</Link>
    </Wrap>
  </>
)
