import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Text } from "../shared/objects"

const Row = styled.article`
  position: relative;
  border-bottom: solid 1px ${props => props.theme.tertiary};

  > a {
    display: flex;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 10%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      right: 12px;
      z-index: 1;
      display: none;
    }

    &:hover {
      background-color: ${props => props.theme.primary};
      &:after {
        display: block;
      }
    }
  }
`

const Thumb = styled.figure`
  flex: 1;
  background-color: ${props => props.theme.tertiary};
  height: 10rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h1`
  flex: 2;
  display: flex;
  align-items: center;
`

const Client = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Year = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Wrap = styled.div`
  padding: 0 0.75rem;
`

export default ({ title, uid, client, year, thumb }) => (
  <Row>
    <Link to={"/project/" + uid}>
      <Thumb>
        <img src={thumb} alt={title} />
      </Thumb>
      <Title>
        <Wrap>
          <Text block bold s18>
            {title}
          </Text>
        </Wrap>
      </Title>
      <Client>
        <Wrap>
          <Text s14>{client}</Text>
        </Wrap>
      </Client>
      <Year>
        <Wrap>
          <Text s14>{year}</Text>
        </Wrap>
      </Year>
    </Link>
  </Row>
)
