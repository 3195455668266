import React from "react"
import styled from "styled-components"

import { getVimeoId } from "../../utilities/get-vimeo-id"

const Embed = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Poster = styled.figure`
  background-color: ${props => props.theme.dim};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  img {
    width: 99%;
    object-fit: cover;
    height: 99%;
  }
`

export default ({ provider, url, autoplay, poster, title }) => {
  const provider_name = provider
  const video_url = url
  const autoPlayState = autoplay ? "?autoplay=true" : ""

  let src =
    provider_name === "Vimeo"
      ? "https://player.vimeo.com/video/" +
        getVimeoId(video_url) +
        autoPlayState
      : video_url

  return (
    <Embed>
      <iframe
        src={src}
        title="video"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />

      {poster && (
        <Poster>
          <img src={poster} alt={title} />
        </Poster>
      )}
    </Embed>
  )
}
