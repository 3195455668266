import React from "react"
import styled, { keyframes } from "styled-components"

import Embed from "../shared/embed"
import { Picture } from "../shared/objects"

const fadeInUp = keyframes`
  0% {
    transform: translate3d(0, 12.5vw, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
`

const Box = styled.section`
  position: relative;
  margin-bottom: 9vw;
  animation: ${fadeInUp} 1s cubic-bezier(0.25, 1, 0.5, 1) both;

  @media screen and (max-width: 766px) {
    padding-top: 9.6rem;
  }
`

export default ({ title, poster, provider, url }) => (
  <Box>
    {url && (
      <Embed
        poster={poster}
        url={url}
        provider={provider}
        title={title}
        autoplay
      />
    )}

    {!url && (
      <Picture>
        <img className="fluidImage" src={poster} alt="" />
      </Picture>
    )}
  </Box>
)
