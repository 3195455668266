import styled from "styled-components"
import {
  easings,
  slideInRight,
  Blink,
  fadeInBottom,
} from "../shared/animations"
import Arrow from "../../images/icons/arrow-ltr-red.svg"

export default styled.nav`
  position: fixed;
  width: ${100 / 8}%;
  bottom: 2.5vw;
  right: ${100 / 8}%;
  padding-left: 1.575rem;
  z-index: 41;
  overflow: hidden;
  transition: opacity 0.75s, visibility 0.75s, transform 0.75s;
  transition-timing-function: ${easings.easeInOutQuad};
  opacity: 1;
  visibility: visible;


  a {
    display: block;
    font-weight: 600;
    padding: 0.45rem 0 0.5rem;
    position: relative;
    font-size: 0.75rem;
    text-transform: lowercase;
    opacity: 0;
    transform-origin: 0 0;
    transition: transform 0.5s, opacity 0.5s;

    &:last-child {
      padding-bottom: 0;
    }

    &:after{
      content: ""; 
      position: absolute;
      right: 0; 
      top: 2px; 
      width: 1.25rem;
      height: 1.25rem;
      background-image: url('${Arrow}'); 
      background-repeat: no-repeat; 
      background-size: contain; 
      transition: transform 0.3s ease-in-out; 
      transform:rotate(-180deg) translate3d(-1rem, 0, 0);
      opacity: 0; 
    }

    span {
      position: relative;
      display: block;
      transition: opacity 0.5s;

      &:after {
        content: "";
        position: absolute;
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 100%;
        background-color: ${props => props.theme.primary};
        left: -0.875rem;
        top: 0.35rem;
        display: none;
        color: ${props => props.theme.primary};
        animation: ${Blink} 1s ease both infinite;
        animation-play-state: paused;
      }

      &.clear-delay {
        animation:none;
      }
    }

    /* on hover */
    &:hover,
    &.is-active {
      opacity: 1;
      transform: translateX(-0.6875rem);

      &:after{
        opacity: 1; 
        transform:rotate(-180deg) translate3d(0, 0, 0);
      }

      span:after {
        display: block;
        animation-play-state: running;
      }
    }

    /* on page home, set new work link to active when projects are in view */
    &.new-work {
      .projects-visible & {
        opacity: 1;
        transform: translateX(-0.6875rem);

        span:after {
          display: block;
        }
      }
    }
  }

  /* hide services menu on non-work pages */
  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateX(50%);

    &:after{
      opacity: 0; 
      visibility: hidden; 
    }
  }

  /* animate on page load */
  .page-has-loaded & {
    a {
      opacity: 0.75;
      span {
        animation: ${slideInRight} 1.5s ${easings.easeInOutCubic} 1.25s both;

        &.clear-delay {
          animation: none; 
        }
      }
    }
  }

  /* remove delay for service menu */
  &.clear-delay {
    span {
      animation-delay: 0s !important;
    }
  }

  /* ipads */
  @media screen and (max-width: 1023px){
    right: 0; 

    a {
      padding: 0.7rem 0 0.7rem;
    }
  }

  /* iphones */
  @media screen and (max-width: 766px){
    display: none;
    
    /* left:calc(14vw + 1px); 
    right: auto; 
    top: 4rem; 
    padding-top: 1rem; 
    padding-left: 0; 
    width: calc(100% - 14vw); 
    padding-right: 2rem; 
    bottom: auto; 
    padding-bottom: 1rem; 
    background: ${props => props.theme.base}; 
    border-bottom: solid 1px ${props => props.theme.tertiary}; 

    div.work {
      display: none;
    }

    a {
      display: inline-block;
      padding: 0.35rem 0 0.35rem 2rem;
      font-size: 0.875rem; 

      &:hover {
        transform: none; 
        opacity: 1; 

        span:after{
          display: none;
        }
      }

      &.is-active {
        color: ${props => props.theme.primary};
        transform: none; 

        span:after{
          display: none;
        }
      }

      &:after{
        display: none;
      }

      &:before {
        content: "/";
        position: absolute;
        right: -1.25rem;
        font-weight: bold;  
        top: 2px;
        color: ${props => props.theme.primary};
        display: none;
      }

      span:after{
        display: none;
      }
    }

    .page-has-loaded & {
      a {
        &:before {
          display: block;
          animation: ${fadeInBottom} 1.5s ${easings.easeInOutCubic} 0.75s both;
        }
        span {
          animation-name: ${fadeInBottom};
        }
      }
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }

    &.is-hidden {
      transform: translateY(50%);
    } */
  }

`


