import React from "react"
import styled, { css } from "styled-components"

import Instagram from "../../images/logos/instagram.svg"
import Vimeo from "../../images/logos/vimeo.svg"
import Youtube from "../../images/logos/youtube-x.svg"
import Facebook from "../../images/logos/facebook.svg"

import { Text } from "../shared/objects"

const Box = styled.div`
  position: fixed;
  width: 13.5vw;
  right: 0;
  top: 5vw;
  z-index: 9;

  @media screen and (max-width: 1023px) {
    width: 12.5vw;
    padding-left: 1.5rem;
  }

  @media screen and (max-width: 1000px) {
    position: relative;
    width: calc(100% - 14vw);
    top: auto;
    right: auto;
    margin-left: 13vw;
    margin-top: 10rem;
    padding-top: 3rem;
    padding-left: 7rem;
    padding-bottom: 4rem;
    border-top: solid 1px ${props => props.theme.tertiary};
  }
`

const Group = styled.div`
  padding: 0 1.25rem 0 0;

  &:last-of-type {
    padding-top: 2rem;
  }

  @media screen and (max-width: 1023px){
    span span {
      display: none;
    }
  }

  @media screen and (max-width: 766px){
    span span {
      display: inline-block;
    }
  }
`

const Link = styled.a`
  display: block;
  padding: 0.125rem 0;
  position: relative;

  opacity: 0.35;
  transition: opacity 0.5s;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.primary};
    height: 1px;
    width: 100%;

    transform-origin: 0 0;
    transition: transform 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    transform: scale3d(0, 1, 1);
  }

  &:hover {
    opacity: 1;

    &:after {
      transform: scale3d(1, 1, 1);
    }
  }

  @media screen and (max-width: 766px){
    opacity: 1; 
    padding-bottom: 1rem; 
  }
`

const Icon = styled.a`
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 1.5rem;
    background-image: url('${props => props.src && props.src}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* filter: grayscale(100%); */

    &:hover{
      filter: none; 
    } 

    ${props =>
    props.yt &&
    css`
        transform: scale(1.3) translateY(0.5px);
        margin-left: 0.125rem;
      `}

    @media screen and (max-width: 1023px) {
      display: block;
      margin-bottom: 1.5rem;
    }

     @media screen and (max-width: 1000px) {
      display: inline-block;
      filter: none;
      margin-right: 2rem;
    }
`

export default () => (
  <Box>
    <Group>
      <Link href="tel:+256414256890">
        <Text s14><span>talk /</span>+256 414 697 298</Text>
      </Link>
    </Group>

    <Group>
      <Link
        href="https://www.google.com/maps/place/Addmaya/@0.3217584,32.6168443,17z/data=!3m1!4b1!4m5!3m4!1s0x177dbbfb032c9da1:0x8aca61b92af23aff!8m2!3d0.321753!4d32.619033"
        target="_blank"
        rel="noreferrer"
      >
        <Text s14><span>visit /</span>bugolobi, uganda</Text>
      </Link>
    </Group>



    <Group>
      <Link href="mailto:ask@addmaya.com">
        <Text s14><span>write /</span>ask@addmaya.com</Text>
      </Link>
    </Group>

    <Group>
      <Icon
        src={Facebook}
        target="_blank"
        href="https://facebook.com/addmaya"
        rel="noreferrer"
      />
      <Icon
        src={Instagram}
        target="_blank"
        href="https://instagram.com/addmayan"
        rel="noreferrer"
      />
      <Icon
        src={Vimeo}
        target="_blank"
        href="https://vimeo.com/addmaya"
        rel="noreferrer"
      />
      <Icon
        yt
        src={Youtube}
        target="_blank"
        href="https://youtube.com/addmaya"
        rel="noreferrer"
      />
    </Group>
  </Box>
)
