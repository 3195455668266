import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href = "https://drive.google.com/file/d/1ENdN8YPBWLfkhsj8FWnCCh2xvUmul-Fl/view?usp=sharing"
  }, [])
  return (
    <>
      <Meta title="Addmaya Fantasy League" />
    </>
  )
}

export default Ele
