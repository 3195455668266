import React from "react"
import styled from "styled-components"

const Box = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12vw;

  ${"" /* full width */}
  &.grid-1-2 .thumb:nth-child(3n + 1), 
    &.grid-2-1 .thumb:nth-child(3n + 3),
    &.grid-1 .thumb {
    .link {
      display: block;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }

    .media {
      width: 100%;
      padding-bottom: 56.25%;
    }

    .category {
      right: 3.5rem;
      left: auto;
    }

    .meta {
      width: 65%;
      float: right;

      &:after {
        display: none;
      }
    }
  }

  ${"" /* right */}
  &.grid-left, &.grid-1-2 .thumb:nth-child(3n + 2), 
    &.grid-2-1 .thumb:nth-child(3n + 2) {
    .link {
      flex-direction: row-reverse;
    }

    .meta {
      &:after {
        left: 0;
        right: auto;
      }

      &:before {
        left: 0;
        right: auto;
      }
    }
  }

  @media screen and (max-width: 766px){

    &.grid-1 .thumb .meta {
      width: 100%;
    }
  }
`



export default ({ className, children }) => {
  return <Box className={className}>{children}</Box>
}
