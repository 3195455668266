import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Media from "./media"
import { Text } from "./objects"
import Section from "./section"
import ArrowNext from "../../images/icons/long-arrow-next.svg"
import ArrowBack from "../../images/icons/long-arrow-back.svg"
import { useMilestonesData } from "../../utilities/use-milestones-data"

const Pager = styled.div`
  width: 3rem;
  height: 4.5rem;
  margin: 0 auto;
  position: relative;

  :after {
    content: "";
    height: 25%;
    width: 1px;
    background-color: ${props => props.theme.primary};
    top: 40%;
    left: 40%;
    position: absolute;
    transform: rotate(45deg);
  }

  span {
    position: absolute;
  }
`

const Active = styled.span`
  top: 0;
  left: 0;
`

const Count = styled.span`
  bottom: 0;
  right: 0;
  opacity: 0.5;
`

const pulse = keyframes`
  0% {
    transform: scale3d(0.75, 0.75, 0.75)
  }

  50% {
    transform: scale3d(1,1,1)
  }

  100% {
    transform: scale3d(0.75, 0.75, 0.75)
  }
`
const Nav = styled.div`
  width: 50%;
  margin: 3rem auto 1.5rem;
  position: relative;

  button {
    height: 4.5rem;
    width: 13rem;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;

    &:hover {
      &:after {
        transform: scale3d(1, 1, 1);
      }

      &:before {
        animation-play-state: paused;
      }
    }

    &:after {
      transform: scale3d(0, 0, 0);
      transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    }

    &:before {
      animation: ${pulse} 2s ease infinite both;
    }

    &:before,
    &:after {
      content: "";
      width: 5.5rem;
      height: 5.5rem;
      border: solid 1px ${props => props.theme.primary};
      position: absolute;
      border-radius: 100%;
      top: -6%;
      right: 12%;
      transform-origin: 50% 50%;
    }
  }
  @media screen and (max-width: 766px) {
    width: 100%;
  }
`

const BackBtn = styled.button`
  background-image: url('${ArrowBack}'); 
  left: 0;

  &:before {
    display: none;
  }
`

const NextBtn = styled.button`
  background-image: url('${ArrowNext}'); 
  right: 0;

  &:after {
    display: none;
  }
`

export default ({ index, title, summary, id, post }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  let items = []

  if (post === "Milestones") {
    useMilestonesData().map((item, index) => {
      let node = item.node.data
      items[index] = {
        photo: node.photo.url,
        title: node.title.text,
        caption: node.summary.text,
        date: node.date,
      }
      return null
    })
  }

  const goNext = () => {
    activeSlide < items.length - 1
      ? setActiveSlide(activeSlide + 1)
      : setActiveSlide(0)
  }

  const goBack = () => {
    activeSlide > 0
      ? setActiveSlide(activeSlide - 1)
      : setActiveSlide(items.length - 1)
  }

  return (
    <Section id={id}>
      <header id={id}>
        <i>
          <Text uppercase s14>
            0{index}
          </Text>
        </i>
        <h2>
          <Text uppercase s14>
            {title}
          </Text>
        </h2>
        {summary && <p>{summary}</p>}
      </header>

      <Media className="photo-slide">
        {items.length > 0 &&
          items.map((item, index) => (
            <div
              key={index}
              className={
                index === activeSlide ? "media-wrap is-visible" : "media-wrap"
              }
            >
              <img src={item.photo} alt="" />

              <p>
                {item.title && (
                  <Text uppercase s14>
                    {item.title}
                  </Text>
                )}

                {item.date && <span className="date">~ {item.date}</span>}

                {item.caption && (
                  <span className="caption">{item.caption}</span>
                )}
              </p>
            </div>
          ))}
      </Media>

      <Nav>
        <BackBtn onClick={goBack} />
        <Pager>
          <Active>
            <Text bold s12>
              {activeSlide}
            </Text>
          </Active>
          <Count>
            <Text bold s12>
              {items.length - 1}
            </Text>
          </Count>
        </Pager>
        <NextBtn onClick={goNext} />
      </Nav>
    </Section>
  )
}
