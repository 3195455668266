export function renderClients(client) {
  let clients = []

  if (client) {
    client.map((item, index) => {
      if (item.project_client) {
        let document = item.project_client.document

        if (document && document.data.name) {
          clients[index] = document.data.name.text
        }
      }
      return null
    })
  }

  return clients
}
