import React from "react"
import { Provider } from "../../utilities/context"
import Reset from "../../utilities/reset"
import Theme from "../../utilities/theme"
import { PageContentWrap } from "../shared/objects"
import Contacts from "./contact"
import FadeFromBlack from "./fade-from-black"
import GridLine from "./grid-line"
import Logo from "./logo"
import Menu from "./menu"
import ServicesMenu from "./services-menu"
import PageMenu from "./page-sections-menu"
import ScrollDownPrompt from "./scroll-down-prompt"
import Grain from "./grain"
import CustomCursor from "../../components/shared/custom-cursor"

export default ({ children }) => {
  return (
    <Provider>
      <Theme>
        <Reset />
        <Grain />
        <Logo />
        <Menu />
        <ServicesMenu />
        <CustomCursor />
        <PageMenu />
        <PageContentWrap>{children}</PageContentWrap>
        <FadeFromBlack />
        <Contacts />
        <GridLine type="pulse" />
        <GridLine type="left" />
        <GridLine type="right" />
        <GridLine type="page-load-progress" />
        <ScrollDownPrompt />
      </Theme>
    </Provider>
  )
}
