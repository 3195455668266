import React from "react"
import { Link } from "gatsby"
import { useGlobalStateContext } from "../../utilities/context"
import { useFeaturedServicesData } from "../../utilities/use-featured-services-data"
import SubMenu from "../layout/submenu"

export default () => {
  const data = useFeaturedServicesData()

  const {
    showServices,
    activeService,
    clickCount
  } = useGlobalStateContext()

  return (
    <SubMenu className={"services " + showServices}>

      <Link to="/#latest-projects" className={activeService === 1 ? 'is-active' : ''}>
        <span className={clickCount > 0 ? 'clear-delay' : ''}>New work</span>
      </Link>

      {data.map((item, index) => (
        <Link activeClassName="is-active" key={index} to={"/service/" + item.node.uid}>
          <span style={{ animationDelay: 1.25 + 0.025 * index + "s" }}>
            {item.node.data.title.text}
          </span>
        </Link>
      ))}

      {/* <Link activeClassName="is-active" to="/museum">
        <span>Addmaya Museum</span>
      </Link> */}
    </SubMenu>
  )
}
