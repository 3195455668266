import React from "react"
import { Link } from "gatsby"

import { Text } from "../shared/objects"
import { useFeaturedServicesData } from "../../utilities/use-featured-services-data"
import Button from "../shared/full-width-button"

export default () => {
  const items = useFeaturedServicesData() ? useFeaturedServicesData() : null

  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Button>
            <Link to={"/service/" + item.node.uid}>
              <div>
                <Text s56 bold>
                  {item.node.data.title.text}
                </Text>
                <Text s56 bold>
                  {item.node.data.title.text}
                </Text>
              </div>
              <figure></figure>

              {item.node.data.reel.url && (
                <video loop muted>
                  <source src={item.node.data.reel.url} type="video/mp4" />
                </video>
              )}

              {
                item.node.data.cover.fluid &&
                <img src={item.node.data.cover.fluid.src} alt="" />
              }

            </Link>
          </Button>
        </li>
      ))}
    </ul>
  )
}
