import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const Button = styled.a`
  position: relative;
  width: 100%;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 0.875rem;
  color: ${props => props.theme.light};
  padding: 2.5vw 0;
  display: block;

  strong {
    color: ${props => props.theme.primary};
  }

  &:after,
  div span,
  figure video, img {
    transition-property: transform;
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    will-change: transform;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: -2;
    transform-origin: 0 100%;
    background: ${props => props.theme.primary};
    transform: scale3d(1, 0, 1);
    mix-blend-mode: multiply;
    transition-delay: 0.1s;
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    overflow: hidden;

    video, img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: grayscale(100%);
      transform: scale3d(1, 0, 1);
      opacity: 1;
      transform-origin: 0 60%;
      position: absolute;
    }

    video {
      z-index: 1;
    }

    img {
      z-index: 0;
    }
  }

  div {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: left;
    top: 2vw;
    overflow: hidden;

    span {
      display: block;
    }
  }

  .hoverLabel {
    z-index: -1;
    span {
      transform: translate3d(0, 100%, 0);
    }
  }

  &:hover {
    &:after {
      transform: scale3d(1, 1, 1);
    }

    figure video, figure img {
      transform: none;
    }

    div {
      span {
        transform: translate3d(0, -100%, 0);
      }
    }

    .hoverLabel {
      span {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  /* iphones */
  @media screen and (max-width: 766px) {
    padding: 2.5rem 0 1rem;
  }
}
`

export default ({ initialLabel, hoverLabel, anchor, videoSrc, image }) => {
  const video = useRef(null)
  const button = useRef(null)

  let firstWord = ""
  let lastWord = ""

  if (initialLabel) {
    firstWord = initialLabel.replace(/ .*/, "")
    lastWord = initialLabel.substring(firstWord.length)
  }

  useEffect(() => {
    if (videoSrc) {
      button.current.addEventListener("mouseenter", () => {
        if (video.current.readyState === 4) {
          video.current.play()
        }
      })

      button.current.addEventListener("mouseleave", () => {
        if (video.current.readyState === 4) {
          video.current.pause()
        }
      })
    }
  })

  return (
    <div className="actions">
      {initialLabel && (
        <Button ref={button} href={anchor}>
          <div>
            <span>
              <strong>{firstWord}</strong> {lastWord}
            </span>
          </div>
          <div className="hoverLabel">
            <span>{hoverLabel}</span>
          </div>

          {(videoSrc || image) && (
            <figure>
              <video ref={video} loop muted>
                <source src={videoSrc} type="video/mp4" />
              </video>

              {image && (
                <img src={image} alt="" />
              )}
            </figure>
          )}
        </Button>
      )}
    </div>
  )
}
