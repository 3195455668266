import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

const Box = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  opacity: 0;
  transform: translate3d(0, 12.5%, 0);
  transition-duration: 1.5s;
  transition-delay: 0.3s;

  &.is-visible {
    transform: none;
    opacity: 1;
  }
`

export default ({ threshold, children }) => {
  let viewThreshold = 0.25

  if (typeof window !== "undefined" && threshold && window.innerWidth < 767) {
    viewThreshold = threshold
  } else {
    viewThreshold = 0
  }

  const [box, boxInView, entry] = useInView({
    threshold: viewThreshold,
  })

  useEffect(() => {
    if (boxInView) {
      entry.target.classList.add("is-visible")
    }
  })

  return <Box ref={box}>{children}</Box>
}
