import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import Icon from "../../images/icons/arrow-ltr.svg"
import { useGlobalStateContext } from "../../utilities/context"

const scaleIn = keyframes`
    0%{
        transform:scale3d(0,0,0)
    }
    100%{
        transform:none;
    }
`
const Box = styled.button`
  position: absolute;
  display: none;
  z-index: 99999;
  pointer-events: none;
  height: 4rem;
  width: 4rem;

  &.true {
    display: flex;

    figure {
      animation: ${scaleIn} 0.25s ease-in-out both;
    }
  }

  &.false {
    display: none;
  }
`

const Arrow = styled.figure`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.primary};
    border-radius: 100%; 
    background-image: url('${Icon}');
    background-repeat: no-repeat; 
    background-size: 40%; 
    background-position: 55% center; 
    position: relative;
    left: -20%;
    top: -20%;
    will-change: transform; 
`

export default () => {
  const { showCursor } = useGlobalStateContext()
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  })

  const onMouseMove = event => {
    const { pageX: x, pageY: y } = event
    setPos({ x, y })
  }

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)
    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  }, [showCursor])

  return (
    <Box
      className={showCursor}
      style={{ transform: "translate3d(" + pos.x + "px, " + pos.y + "px, 0)" }}
    >
      <Arrow></Arrow>
    </Box>
  )
}
