import React, { useState } from "react"
import { useMayansData } from "../../utilities/use-mayans-data"
import Mayan from "../shared/mayan"
import Section from "../shared/section"
import Bio from "../shared/bio"
import { Text } from "../shared/objects"
import styled from 'styled-components'

const MayansWrap = styled.div`
  padding-top: 5vw;
`

export default ({ id }) => {
  const data = useMayansData()
  const [active, setActive] = useState(null)

  return (
    <Section id={id} className="cancel-scroll-animation">
      <header id="mayans">
        <i>
          <Text uppercase s14>
            01
          </Text>
        </i>
        <h2>
          <Text uppercase s14>
            The Mayans
          </Text>
        </h2>
        <p>Over the years, we have evolved to appreciate what each Mayan brings to the table above their title. We love being anonymous but if you look closely, we are everywhere.</p>
      </header>

      <Bio className={active !== null ? "is-visible" : null}>
        {data.map((item, index) => (
          <div key={index} className={index === active ? "is-visible" : null}>
            <h2>
              <Text s14 uppercase>
                {item.node.data.name.text}
              </Text>
              <span className="title">{item.node.data.title.text}</span>
            </h2>
            <img
              src={item.node.data.photo.url}
              alt={item.node.data.name.text}
            />
          </div>
        ))}
      </Bio>

      <MayansWrap >
        {data.map((item, index) => (
          <Mayan
            key={index}
            index={index}
            nickname={item.node.data.nickname.text}
            name={item.node.data.name.text}
            title={item.node.data.title.text}
            photo={item.node.data.photo.url}
            mouseEnter={() => {
              setActive(index)
            }}
            mouseLeave={() => {
              setActive()
            }}
          />
        ))}
      </MayansWrap>
    </Section>
  )
}
