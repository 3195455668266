import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const blink = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 1.25rem, 0) scale3d(1,1.35,1);
    }

    50% {
        opacity: 1;
        transform: scale3d(1,1,1);
    }

    100% {
        opacity: 0;
        transform: none;
    }
`

const Label = styled.div`
  position: fixed;
  width: 100%;
  bottom: 2.5rem;
  left: 0;
  z-index: 10;
  pointer-events: none;

  animation: ${fadeIn} 1.35s cubic-bezier(0.33, 1, 0.68, 1) 1.25s both;
  animation-play-state: paused;

  .page-has-loaded & {
    animation-play-state: running;
  }

  div {
    display: block;
    text-transform: uppercase;
    font-size: 0.55rem;
    text-align: center;
    letter-spacing: 0.5rem;
    padding-left: 12.5%;
    padding-right: 25%;

    opacity: 0;
    transform: translate3d(0, -50%, 0);
    transition: transform 1.5s, opacity 1.5s;
  }

  span {
    display: block;
    padding-bottom: 0.3rem;
    font-weight: bold;
    animation: ${blink} 1.5s ease infinite both;
    animation-play-state: paused;
    margin: 0 auto;

    &.dot {
      height: 1.5rem;
      width: 1.5rem;
      border: solid 3px ${props => props.theme.primary};
      border-radius: 100%;
    }
  }

  &.is-visible {
    div {
      opacity: 1;
      transform: none;
    }

    span {
      animation-play-state: running;
    }
  }

  @media screen and (max-width: 766px) {
    bottom: 6rem;
    width: calc(100% - 14vw);
    left: 14vw;
  }
`

export default () => {
  const [visible, setVisible] = useState("is-visible")

  useEffect(() => {
    window.onscroll = () => {
      let windowScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      let pageHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight

      windowScroll / pageHeight > 0 ? setVisible("") : setVisible("is-visible")
    }
  })

  return (
    <Label className={visible}>
      <div>
        <span className="dot"></span>
        {/* <span className="stick"></span> */}
      </div>
    </Label>
  )
}
