import React from "react"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import { useMomentsData } from "../../utilities/use-moments-data"
import { useGlobalStateContext } from "../../utilities/context"
import { ShuffleArray } from "../../utilities/shuffleArray"
import Wrap from "./preface/wrap"
import Title from "./preface/title"
import Summary from "./preface/summary"
import MediaWrap from "./preface/media"
import Action from "./preface/action"
import { useState } from "react"

export default ({
  layout,
  title,
  summary,
  list,
  buttonDefaultLabel,
  buttonHoverLabel,
  buttonVideo,
  buttonAnchor,
  page,
  posts,
  images,
  cover
}) => {
  let media = []

  //featured projects
  if (page === "work" && posts.length > 0) {
    posts.map((project, index) => {
      if (index < 5) {
        let item = {}
        let node = project.post.document.data

        //photo
        item.photo = node.photo.fluid.src

        //video
        if (node.loop.url) {
          item.video = node.loop.url
        }
        media.push(item)
      }
      return null
    })
  }

  //random moments
  if (page === "team") {
    let moments = useMomentsData()
    moments = ShuffleArray(moments)

    moments.map((moment, index) => {
      if (index < 5) {
        let item = {}
        item.photo = moment.node.data.image.fluid.src
        media.push(item)
      }
      return null
    })
  }

  //service featured images
  if (images) {
    media = images
  }

  //animate elements when in view
  const [prefaceTitle, titleInView, titleEntry] = useInView()
  const [prefaceSummary, summaryInView, summaryEntry] = useInView()

  const { clickCount } = useGlobalStateContext()

  useEffect(() => {
    if (titleInView) {
      titleEntry.target.classList.add("is-visible")
    }

    if (summaryInView) {
      summaryEntry.target.classList.add("is-visible")
    }
  }, [titleInView, summaryInView])

  //get random grid layout
  let [mediaClass, setMediaClass] = useState("")
  useEffect(() => {
    let mediaLayouts = ["grid-a", "grid-b", "grid-c", "grid-d", "grid-e"]
    setMediaClass(mediaLayouts[Math.floor(Math.random() * mediaLayouts.length)])
  }, [])

  return (
    <Wrap className={layout}>
      {/* title */}
      {title && (
        <Title
          dangerouslySetInnerHTML={{ __html: title }}
          ref={prefaceTitle}
          className={clickCount > 0 ? "clear-delay" : null}
        />
      )}

      {/* summary */}
      <Summary
        ref={prefaceSummary}
        className={clickCount > 0 ? "clear-delay" : null}
      >
        <p>{summary}</p>

        {buttonDefaultLabel && (
          <Action
            initialLabel={buttonDefaultLabel}
            hoverLabel={buttonHoverLabel}
            anchor={buttonAnchor}
            videoSrc={buttonVideo}
            image={cover ? cover : null}
          />
        )}

        {(list && list.length > 0) && (
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </Summary>

      {/* photos and videos */}
      {media && (
        <MediaWrap className={mediaClass}>
          {media.map((item, index) => {
            let delay = (Math.random() * (2.15 - 0.65) + 0.65).toFixed(2)
            let duration = (Math.random() * (5 - 2.5) + 2.5).toFixed(2)

            return (
              <figure key={index}>
                <div>
                  {/* photo */}
                  {!item.video && (
                    <img
                      alt=""
                      src={item.photo}
                      style={{
                        animationDelay: delay + `s`,
                        animationDuration: duration + `s`,
                      }}
                    />
                  )}

                  {/* video */}
                  {item.video && (
                    <video
                      loop
                      muted
                      autoPlay
                      playsinline
                      poster={item.photo}
                      style={{
                        animationDelay: delay + `s`,
                        animationDuration: duration + `s`,
                      }}
                    >
                      <source type="video/mp4" src={item.video}></source>
                    </video>
                  )}
                </div>
              </figure>
            )
          })}
        </MediaWrap>
      )}
    </Wrap>
  )
}
