import React from "react"

import Section from "../shared/section"
import List from "../shared/list"
import { Text } from "../shared/objects"

import { useServicesData } from "../../utilities/use-services-data"
import { useDirectorsData } from "../../utilities/use-directors-data"
import { useAwardsData } from "../../utilities/use-awards-data"

export default ({ index, title, summary, post, credits, id }) => {
  const columns = post === "Services" ? true : null
  const items = []

  if (post === "Services") {
    useServicesData().map((item, index) => {
      items[index] = {
        content: item.node.data.title.text,
        slug: item.node.uid,
      }
      return null
    })
  }

  if (post === "Board of Directors") {
    useDirectorsData().map((item, index) => {
      items[index] = {
        content: item.node.data.name.text,
        byline: item.node.data.title.text,
      }
      return null
    })
  }

  if (post === "Awards") {
    useAwardsData().map((item, index) => {
      items[index] = {
        content: item.node.data.title.text,
        byline:
          item.node.data.award.text +
          " - " +
          item.node.data.date.substring(0, 4),
      }
      return null
    })
  }

  if (credits) {
    credits.map((item, index) => {
      items[index] = {
        content: item.name.text,
        byline: item.role.text,
      }

      return null
    })
  }

  return (
    <Section id={id}>
      <header>
        <i>
          <Text uppercase s14>
            0{index}
          </Text>
        </i>
        <h2>
          <Text uppercase s14>
            {title}
          </Text>
        </h2>
        {summary && <p>{summary}</p>}
      </header>

      {items && <List type={post} columns={columns} items={items} />}
    </Section>
  )
}
