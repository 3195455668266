import styled, { keyframes } from 'styled-components'

let delay = `1.25s`

const slideInLeft = keyframes`
    from {
        transform: translate3d(125%,0,0);
        opacity: 0; 
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1; 
    }
`

const slideInBottom = keyframes`
    from {
        transform: translate3d(0,125%,0);
        opacity: 0; 
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1; 
    }
`

export default styled.nav`
  position: fixed;
  width: 12.5vw;
  top: 5vw;
  left: 0;
  padding-bottom: 1.5rem;
  z-index: 20;

  a {
    display: block;
    font-weight: 600;
    position: relative;
    text-align: right;
    padding: 0.5rem 0;
    transition: color 0.25s;
    animation: ${slideInLeft} 1.25s cubic-bezier(0.62, -0.01, 0.25, 0.99)
        ${delay} both;
    animation-play-state: paused;
    

    /* hover */
    &:hover {
      ${props => props.theme.primary};
    }

    /* active */
    &.is-active {
      color: ${props => props.theme.primary};
    }

    &.clear-delay{
      animation: none;
    }
  }

  /* on page load */
  .page-has-loaded & {
    a {
      animation-play-state: running;
    }
  }

  /* ipads */
  @media screen and (max-width: 1023px){
    a {
      padding-bottom: 1.125rem;
    }
  }

  /* iphones */
  @media screen and (max-width: 766px) {
    width: calc(100% - 14vw);
    left: calc(14vw + 1px);
    top: 0;
    padding-bottom: 0;
    z-index: 42;

    a {
      display: inline-block;
      text-align: left;
      padding: 1.75rem 1.35rem;
    }

    .page-has-loaded & {
      background: ${props => props.theme.base};

      a {
        animation-name: ${slideInBottom};
      }
    }
  }
`
