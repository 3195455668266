import React from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"
import Filters from "../components/archive/filters"
import Card from "../components/archive/item"
import { useGlobalDispatchContext } from "../utilities/context"
import { useEffect } from "react"

function render_project(item, index) {
  const node = item.node
  return (
    <Card
      key={index}
      uid={node.uid}
      title={node.data.title.text}
      thumb={node.data.photo.url}
      year="TV Commercial"
      client="Addmaya"
      category="VFX"
    />
  )
}

export default ({ data }) => {
  const projects = data.allPrismicProject.edges
  const dispatch = useGlobalDispatchContext()

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_MENU",
      activeMenuItem: "museum",
    })
  })

  return (
    <>
      <Meta title="Archive" />
      <Filters />
      <section>{projects.map(render_project)}</section>
    </>
  )
}

export const query = graphql`
  {
    allPrismicProject(sort: { order: DESC, fields: data___date }) {
      edges {
        node {
          data {
            photo {
              url
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`
