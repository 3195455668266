import styled from "styled-components"

export default styled.section`
  min-height: calc(50vw - 7.5vw);
  position: relative;

  &.fluid,
  &.fluidheight {
    min-height: 0;
    section {
      margin-bottom: 10vw;
    }
  }

  &.fullcanvas {
    min-height: 0;
    height: calc(55vw - 5vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 766px) {
    min-height: 0;
    padding-top: 6.5rem !important;
    padding-bottom: 5.5rem;

    &.fullcanvas {
      height: auto;
    }
  }
`
