import { useStaticQuery, graphql } from "gatsby"

export const useMayansData = () => {
  const data = useStaticQuery(
    graphql`
      query MayansQuery {
        allPrismicStaff {
          edges {
            node {
              data {
                title {
                  text
                }
                photo {
                  url
                  fluid {
                    src
                  }
                }
                nickname {
                  text
                }
                name {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicStaff.edges
}
