
import styled from 'styled-components'

export default styled.div`
  float: right;
  padding-right: 1.125rem;
  width: 50%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 766px) {
    float: none;
    padding-right: 0;
    width: 100%;
    border-bottom: solid 1px ${props => props.theme.tertiary};
  }
`