import React, { useState } from "react"
import { graphql } from "gatsby"
import Meta from "../utilities/seo"
import { ShuffleArray } from "../utilities/shuffleArray"
import Preface from "../components/slices/preface"
import Project from "../components/project/thumbnail"
import ProjectsGrid from "../components/shared/projects-grid"
import { useGlobalDispatchContext } from "../utilities/context"
import { useEffect } from "react"
import BackButton from '../components/shared/back-button'

export default ({ data }) => {
  const service = data.allPrismicService.edges[0].node.data
  const service_title = service.title ? service.title.text : null
  const service_summary = service.summary ? service.summary.text : null
  const service_children = service.sub_services ? service.sub_services : null
  const service_projects = data.allPrismicProject.edges
    ? data.allPrismicProject.edges
    : null

  //set work to active
  const dispatch = useGlobalDispatchContext()
  useEffect(() => {

    if(service_title !== 'Kavuyo'){
      dispatch({
        type: "SET_ACTIVE_MENU",
        activeMenuItem: "work",
      })
    } else {
      dispatch({
        type: "SET_PAGE_SECTIONS",
        pageSections: {}
      })

      dispatch({
        type: "SET_ACTIVE_MENU",
        activeMenuItem: '',
      })

      dispatch({
        type: "TOGGLE_SERVICES_MENU",
        showServices: ""
      })
    }
   
    dispatch({
      type: "set_active_service",
      activeService: 0,
    })
  }, [dispatch])

  //cover images
  let covers = []
  let meta_image = []

  service_projects.map((item, index) => {
    let photo = item.node.data.photo
    let media = {}
    media.photo = photo.url

    if (item.node.data.loop) {
      media.video = item.node.data.loop.url
    }

    covers.push(media)

    if (index === 0) {
      meta_image = {
        url: photo.url,
        width: photo.dimensions.width,
        height: photo.dimensions.height,
      }
    }

    return null
  })
  covers = ShuffleArray(covers)
  covers = covers.slice(0, 5)

  //sub services
  let subservices = []
  service_children.map((item, index) => {
    if (item.subservice_title.text) {
      subservices[index] = item.subservice_title.text
    }
    return null
  })

  //work grid formats
  const [gridClass, setGridClass] = useState('grid-left')
  let showFullWidthThumbs =
    service_title === "Digital" || service_title === "Ad Campaigns"

  useEffect(() => {
    if (showFullWidthThumbs) {
      setGridClass('grid-1')
    }

    if (service_title === "Documentaries" && window.innerWidth > 766) {
      setGridClass('grid-2-1')
    }
  }, [])

  return (
    <>
      <Meta
        title={service_title}
        image={meta_image}
        description={service_summary}
      />

      <BackButton />

      <Preface
        layout="fluid"
        title={"<h1>" + service_title + "</h1>"}
        summary={service_summary}
        list={subservices}
        images={covers}
      />

      <ProjectsGrid className={gridClass}>
        {service_projects.map((item, index) => (
          <Project
            slug={item.node.uid}
            key={index}
            thumb={
              showFullWidthThumbs
                ? item.node.data.photo.url
                : item.node.data.photo.fluid.src
            }
            loop={item.node.data.loop.url}
            title={item.node.data.title.text}
            client={item.node.data.client}
            service={item.node.data.primary_service}
          />
        ))}
      </ProjectsGrid>
    </>
  )
}

export const query = graphql`
  query ServiceQuery($uid: String) {
    allPrismicProject(
      filter: { data: { primary_service: { uid: { eq: $uid } } } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            photo {
              url
              dimensions {
                height
                width
              }
              fluid {
                src
              }
            }
            loop {
              url
            }
            title {
              text
            }
            primary_service {
              document {
                ... on PrismicService {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            client {
              project_client {
                document {
                  ... on PrismicClient {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allPrismicService(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            sub_services {
              subservice_title {
                text
              }
            }
            title {
              text
            }
            summary {
              text
            }
            reel {
              url
            }
          }
        }
      }
    }
  }
`
