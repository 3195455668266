import React, { useEffect, useState } from "react"
import SubMenu from "../layout/submenu"
import { useGlobalStateContext } from "../../utilities/context"

export default () => {
  const { pageSections, clickCount, showServices } = useGlobalStateContext()
  const [showMenu, setShowMenu] = useState()
  useEffect(() => {
    if (showServices === "is-hidden") {
      setShowMenu("show")
    } else {
      setShowMenu("hide")
    }
  })

  return (
    <SubMenu className={clickCount > 0 ? "clear-delay " + showMenu : showMenu}>
      {pageSections.length &&
        pageSections.map((item, index) => (
          <a key={item.anchor} href={"#" + item.anchor}>
            <span>{item.label}</span>
          </a>
        ))}
    </SubMenu>
  )
}
