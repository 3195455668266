import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

const Box = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  background-color: ${props => props.theme.dim};

  video, img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    z-index: 1;
  }

  img {
    z-index: 0;
  }
`

export default ({ poster, src }) => {
  const player = useRef()
  const [video, videoInView] = useInView()

  useEffect(() => {
    if (videoInView) {
      player.current.play()
    }
  }, [videoInView])

  return (
    <Box ref={video}>
      {poster &&
        <img src={poster} alt="" />
      }
      <video ref={player} loop poster={poster} muted playsinline>
        <source src={src} type="video/mp4" />
      </video>
    </Box>
  )
}
