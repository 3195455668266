import React from "react"

import { Text } from "../shared/objects"
import Section from "../shared/section"
import VideoAlbum from "./video-album"
import PhotoAlbum from "./photo-album"

export default ({ index, title, summary, slices }) => (
  <Section className="cancel-scroll-animation">
    <header>
      <i>
        <Text s14>{index}</Text>
      </i>
      <h2>
        <Text uppercase s14>
          {title}
        </Text>
      </h2>

      {summary && <p>{summary}</p>}
    </header>

    {slices.map((slice, index) => {
      if (slice.slice_type === "photos") {
        return (
          <PhotoAlbum key={index} meta={slice.primary} items={slice.items} />
        )
      }

      if (slice.slice_type === "videos") {
        return (
          <VideoAlbum key={index} meta={slice.primary} items={slice.items} />
        )
      }

      return null
    })}
  </Section>
)
