import React from "react"

import Section from "../shared/section"
import Header from "./header"
import { Picture } from "../shared/objects"

export default ({ meta, items }) => {
  const title = meta.photo_album_title ? meta.photo_album_title.text : ""
  const summary = meta.photo_album_summary ? meta.photo_album_summary.text : ""
  const layout = meta.photo_album_layout.toLowerCase().replace(/\s/g, "")

  console.log(items)
  return (
    <Section>
      <Header title={title} excerpt={summary} />

      <div className={layout}>
        {items.map((item, index) => (
          <Picture key={index}>
            <img className="fluidImage" src={item.photo.fluid ? item.photo.fluid.src : ''} alt="" />
          </Picture>
        ))}
      </div>
    </Section>
  )
}
