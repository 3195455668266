import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
    *, 
    *::before,
    *::after {
        box-sizing: border-box;
    }

    ::-moz-selection {
        background: ${props => props.theme.base}; 
        color: ${props => props.theme.secondary};
    }
    ::selection {
        background: ${props => props.theme.primary}; 
        color: ${props => props.theme.base};
    }

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block
    }

    ol,
    ul {
        list-style: none
    }

    blockquote,q {
        quotes: none
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none
    }
    strong {
        font-weight: bold
    }

    em {
        font-style: italic
    }

    img {
        max-width: 100%;
        display: block;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0
    }

    a {
        text-decoration: none;
        outline: 0;
        color: inherit;
    }
    input,
    button,
    textarea,
    select {
        font: inherit;
        border: none;
        background: none;
        font-size: 100%;
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        border-radius: 0;
        cursor: pointer;
        margin: 0;
        padding: 0;
    }

    input::-moz-placeholder {
        opacity: 1
    }

    textarea::-moz-placeholder {
        opacity: 1
    }

    input::-moz-focus-inner {
        border: 0;
        padding: 0
    }

    input {
        -webkit-appearance: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    html{
        font-size: 16px; 
        ${"" /* scroll-behavior: smooth; */}

        @media (min-width: 1800px){
            font-size:0.9vw;
        }

        @media (max-width: 1500px){
            font-size:14px;
        }

        @media (max-width: 1200px){
            font-size:12px;
        }
    }

    body {
        font-family: 'gilroy', sans-serif;
        -webkit-font-smoothing: antialiased;
        width: 100vw;
        overflow-x: hidden;
        height: 100vh;
        overflow: hidden;
        
        color: ${props => props.theme.secondary};
        background: ${props => props.theme.base};

        transition: background 0.5s, color 0.5s;  

        &.page-has-loaded {
            height: auto;
            overflow: visible;
            overflow-x: hidden;
        }

        @media screen and (max-width: 766px){
            width: auto; 
            height: auto; 
            overflow: visible;
            overflow-x: visible; 
        }
    }

    h1 {
        font-size: 5.25rem;
        font-weight: bold;
    }

    p {
        line-height: 2
    }

    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{
        @media (max-width: 767px){
            font-size: 16px;
        }
    }


.grid1-2{
    display:flex; 
    flex-wrap: wrap;

    figure{
        box-sizing: border-box;

        &:nth-child(3n + 1){
            width: 100%;
        }

        &:nth-child(3n + 2), &:nth-child(3n + 3){
            width: 50%;
        }
    }
}

.grid2-1{
    display:flex; 
    flex-wrap: wrap;

    figure{
        box-sizing: border-box;

        &:nth-child(3n + 3){
            width: 100%;
        }

        &:nth-child(3n + 1), &:nth-child(3n + 2){
            width: 50%;
        }
    }
}

.grid1-3-2{
    display: flex;
    flex-wrap: wrap;

    figure{
        &:nth-child(6n + 1){
            width: 100%;
        } 
        &:nth-child(6n + 2), &:nth-child(6n + 3), &:nth-child(6n + 4){
            width: percentage(1/3);
        } 
        &:nth-child(6n + 5), &:nth-child(6n + 6){
            width: 50%;
        } 
    }
}

.grid2{
    ${'' /* display: flex;
    flex-wrap: wrap; */}
    display: block;
    column-count: 2;
    column-gap: 0; 

    @media screen and (max-width: 766px){
        column-count: 1; 
    }
}

.grid1{
    display: flex;
    flex-wrap: wrap;

    figure{
        width: 100%;
    }
}


body::-webkit-scrollbar {
  width: 2px
}

body::-webkit-scrollbar-track {
  background:#000;
}

body::-webkit-scrollbar-thumb {
  background:#ff0000;
}

body::-webkit-scrollbar-thumb:hover {
  background:#ff0000;
}

html {
  ${"" /* overscroll-behavior-y: none; */}
}

`
