import styled from "styled-components"
import Arrow from "../../../images/icons/arrow-up.svg"

export default styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 10vw;

    button {
    display: inline-flex;
    text-align: center;
    padding: 0;
    padding: 3rem 1.5rem;
    margin: 0;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      top: 20%;
      background-image: url('${Arrow}');
      background-repeat: no-repeat;
      background-position: center; 

      height: 1.25rem;
      width: 1.25rem;
      display: none;
    }

    &:hover,
    &.is-active {
      color: ${props => props.theme.primary};
      &:after {
        display: block;
      }
    }
  }

  @media screen and (max-width: 766px){
    width: 100%; 
    overflow: scroll;
    margin-bottom: 20vw; 
    
    button {
      padding: 2.5rem;
      span {
        display: block;
        line-height: 1.7;
      }

      &:after{
        top: 12%; 
      }
    }
  }
`
