import React from "react"
import styled from "styled-components"
import { FadeOut } from "../shared/objects"
import { useRef } from "react"
import { useEffect } from "react"

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${props => props.theme.base};

  animation: ${FadeOut} 1s ease 2s both;
  animation-play-state: paused;

  .page-has-loaded & {
    animation-play-state: running;
  }

  &.is-hidden {
    display: none;
  }
`

export default () => {
  const box = useRef(null)

  useEffect(() => {
    box.current.addEventListener("animationend", function () {
      this.classList.add("is-hidden")
      document.getElementsByTagName("body")[0].classList.add("show-scrollbars")
    })
  })

  return <Wrap ref={box} />
}
