import React from "react"
import { ThemeProvider } from "styled-components"

const dark = {
  primary: "#ff0000",
  base: "#000000",
  secondary: "#ffffff",
  tertiary: "#222222",
  grey: "#4b4b4b",
  light: "#ffffff",
  dim: "#1a1a20",
}

// const light = {
//   primary: "#ff0000",
//   base: "#ffffff",
//   secondary: "#000",
//   tertiary: "#222222",
//   grey: "#4b4b4b",
//   light: "#ffffff",
//   dim: "#1a1a20",
// }

export default ({ children }) => {
  return <ThemeProvider theme={dark}>{children}</ThemeProvider>
}
