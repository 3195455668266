import React, { createContext, useReducer, useContext } from "react"

const defaultState = {
  theme: "dark",
  showServices: "",
  activeMenuItem: "home",
  activeService: 0,
  clickCount: 0,
  pageSections: {},
  showPageSections: "",
  showCursor: false,
}

const GlobalStateContext = createContext(defaultState)
const GlobalDispatchContext = createContext()

const globalReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SERVICES_MENU": {
      return {
        ...state,
        showServices: action.showServices,
      }
    }

    case "SHOW_CUSTOM_CURSOR": {
      return {
        ...state,
        showCursor: action.showCursor,
      }
    }

    case "SET_ACTIVE_MENU": {
      return {
        ...state,
        activeMenuItem: action.activeMenuItem,
      }
    }

    case "set_active_service": {
      return {
        ...state,
        activeService: action.activeService,
      }
    }

    case "SET_CLICK_COUNT": {
      return {
        ...state,
        clickCount: action.clickCount,
      }
    }

    case "SET_PAGE_SECTIONS": {
      return {
        ...state,
        pageSections: action.pageSections,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const Provider = ({ children }) => {
  let [state, dispatch] = useReducer(globalReducer, defaultState)

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
export const useGlobalStateContext = () => useContext(GlobalStateContext)
