import styled from "styled-components"

import { Blink } from "../../shared/animations"

export default styled.div`
    display: block;
    padding-left: 3.5rem;
    padding-top: 3.5vw;
    width: 65%;
    overflow: hidden;
    /* border-bottom: solid 1px red; */
    
    .fullcanvas & {
        padding-left: 1rem;
        width: 100%;
        padding-top: 0;

        h1 {
            padding-top: 0;
            padding-left: 1.25rem;
            font-size: 7.2rem;
            width: 100%;
        }
    }

    h1 {
        text-transform: lowercase;
        font-size: 5.25rem;
        letter-spacing: -1px;
        font-weight: bold;
        line-height: 1;

        transition: transform 0.9s ease-in-out, opacity 1s;
        transition-delay: 1.45s;
        opacity: 0;
        transform: translate3d(0, 15vh, 0) scale3d(1, 1, 1) skewY(25deg);
        transform-origin: 0 0;

        strong {
            color: ${props => props.theme.primary};
            display: inline-block;
            position: relative;

            transition: transform 1.5s 1.25s;
            transform: translate3d(0, 20vh, 0) scale3d(1.5, 1, 1);

            &:before {
                content: "";
                position: absolute;
                height: 0.5rem;
                width: 0.5rem;
                border-radius: 100%;
                left: 48%;
                background-color: ${props => props.theme.primary};
                /* animation: ${Blink} 1s ease both infinite;
                animation-play-state: paused; */

                z-index: 100;
            }
        }
    }

    &.is-visible {
        h1 {
            transform: none;
            opacity: 1;

            strong {
                transform: none;
                &:before {
                    animation-play-state: running;
                }
            }
        }
    }

    &.clear-delay {
        h1,
        strong {
            transition-delay: 0s;
        }
    }

    /* iphones */
    @media screen and (max-width: 766px){
        padding: 0; 
        width: 100%;
        padding: 0 2rem;
        
        .fullcanvas & {
            padding: 0 2rem;  

            h1 {
                padding: 0; 
                padding-top: 4.5rem;
                padding-bottom: 1.5rem;
                font-size: 3.75rem; 
            }       
        } 

        h1  {
            font-size: 3rem; 
            padding: 0; 
            line-height: 1;
            padding: 4rem 0 1rem; 
        }
    }
`
