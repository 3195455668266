import React from "react"
import styled, { keyframes } from "styled-components"

const scaleOut = keyframes`
    from {
        transform: translate3d(12.5vw, 0,0) scale3d(1,1,1);
    }
    to {
        transform: translate3d(12.5vw, 0,0) scale3d(1,0,1);
    }
`

const scaleOutMobile = keyframes`
    from {
        transform: translate3d(14vw, 0,0) scale3d(1,1,1);
    }
    to {
        transform: translate3d(14vw, 0,0) scale3d(1,0,1);
    }
`

const scaleIn = keyframes`
    from {
        transform: translate3d(0, 0,0) scale3d(1,0,1);
    }
    to {
        transform: translate3d(0, 0,0) scale3d(1,1,1);
    }
`

const pulse = keyframes`
  0% {
    transform: scale3d(1, 0, 1) translate3d(0, 0, 0);
  }

  50% {
    transform: scale3d(1, 0.125, 1);
  }

  100% {
    transform: scale3d(1, 0, 1) translate3d(0, 0, 0);
  }
`

const Line = styled.div`
  position: fixed;
  width: 1px;
  height: 100vh;
  z-index: 30;
  top: 0;

  &.left,
  &.pulse {
    transform: translate3d(12.5vw, 0, 0);
  }

  &.pulse {
    z-index: 900;

    .fill {
      transform-origin: 0 50%;
      background-color: ${props => props.theme.primary};
      animation: ${pulse} 1s ease-in-out both infinite;
    }
  }

  &.right {
    transform: translate3d(75vw, 0, 0);
    .fill {
      animation: ${scaleIn} 1s cubic-bezier(0.62, -0.01, 0.25, 0.99) 1s both;
      animation-play-state: paused;
    }
  }

  &.page-load-progress {
    z-index: 40;
    animation: ${scaleOut} 0.5s ease 1.25s both;
    animation-play-state: paused;
    transform-origin: 0 0;

    .fill {
      transform-origin: 0 50%;
      background-color: ${props => props.theme.primary};
      animation: ${scaleIn} 1s cubic-bezier(0.62, -0.01, 0.25, 0.99) both;
      animation-play-state: paused;
    }
  }

  /* animate on on page load */
  .page-has-loaded & {
    &.pulse {
      display: none;
    }

    &.right .fill {
      animation-play-state: running;
    }

    &.page-load-progress {
      animation-play-state: running;
      .fill {
        animation-play-state: running;
      }
    }
  }

  /* ipad */
  @media (max-width: 1023px){
    &.right {
      transform: translate3d(87.5vw, 0, 0);
    }
  }

  /* mobile */
  @media screen and (max-width: 766px) {
    &.right {
      display: none;
    }

    &.left,
    &.pulse {
      transform: translate3d(14vw, 0, 0);
    }

    &.page-load-progress {
      animation-name: ${scaleOutMobile};
    }
  }
`


const Fill = styled.span`
  background-color: ${props => props.theme.tertiary};
  transform-origin: 0 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export default ({ type }) => (
  <Line className={type}>
    <Fill className="fill" />
  </Line>
)
