import { useStaticQuery, graphql } from "gatsby"

export const useDirectorsData = () => {
  const data = useStaticQuery(
    graphql`
      query DirectorsQuery {
        allPrismicDirector {
          edges {
            node {
              data {
                title {
                  text
                }
                name {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicDirector.edges
}
