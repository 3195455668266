import { useStaticQuery, graphql } from "gatsby"

export const useMomentsData = () => {
  const data = useStaticQuery(
    graphql`
      query MayanQuery {
        allPrismicMoment {
          edges {
            node {
              data {
                image {
                  url
                  fluid {
                    src
                  }
                }
                video {
                  url
                }
                caption {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicMoment.edges
}
