import React from "react"
import styled from "styled-components"
import { useMomentsData } from "../../utilities/use-moments-data"
import FadeInUp from "../shared/fadeInUp"
import Section from '../shared/section'
import { Text } from "../shared/objects"

const Box = styled.section`
  position: relative;
  column-count: 2;
  column-gap: 0;
  padding-top: 5rem;
  padding-bottom: 10rem;
`

const Moment = styled.div`
  position: relative;
  overflow: hidden;
  padding: 4.5rem 4.5rem 6.5rem 0;
  break-inside: avoid;

  &:first-child {
    top: -5rem;
  }

  &:nth-child(2n + 2) {
    padding-right: 0;
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    div {
      position: absolute;
      padding-bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  .caption {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    display: block;
    font-size: 0.875rem;
    position: absolute;
    bottom: 0;
    background-color: #000;
    width: 80%;
    padding: 0.5rem;
  }

  @media screen and (max-width: 766px) {
    padding: 4.5rem 0.5rem 0.5rem 0;

    .caption {
      font-size: 1rem;
      position: relative;
      padding: 1rem;
      width: 100%;
      line-height: 1.5;
    }
  }
`

export default () => {
  const data = useMomentsData()

  return (
    <Section id="moments-1" className="cancel-scroll-animation">
      <header>
        <i>
          <Text uppercase s14>
            02
          </Text>
        </i>
        <h2>
          <Text uppercase s14>
            Moments
          </Text>
        </h2>
        <p>Work is work and anything outside work is a moment to bond, go bananas and have some fun.</p>
      </header>
      <Box>
        {data &&
          data.map((item, index) => {
            let node = item.node.data
            return (
              <Moment key={index}>
                <FadeInUp>
                  <img src={node.image.fluid.src} alt={node.caption.text} />
                  <span className="caption">{node.caption.text}</span>
                </FadeInUp>
              </Moment>
            )
          })}
      </Box>
    </Section>

  )
}
