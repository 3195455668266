import React, { useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import Dropdown from "../../images/icons/dropdown.svg"

const Select = styled.div`
  flex: 1;
  position: relative;

  &:last-child {
    > a {
      border: none;
    }
  }
`

const Label = styled.a`
  display: block;
  padding: 1.5rem 0.75rem 1.5rem 1.125rem;
  border-right: solid 1px ${props => props.theme.tertiary};
  font-weight: 600;
  position: relative;
  cursor:pointer;

  &:after {
    content: "";
    position: absolute;
    right: 1rem;
    width: 1.125rem;
    height: 1.125rem;
    top: 1.375rem;
    background-image: url('${Dropdown}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
  }
`

const Options = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;

  a {
    display: block;
    padding: 1.125rem 1.5rem 1.125rem 0.75rem;
    border-bottom: solid 1px ${props => props.theme.base};
    background-color: ${props => props.theme.tertiary};
    position: relative;

    &:after {
      content: "";
      height: 0.75rem;
      width: 0.75rem;
      top: 1.125rem;
      position: absolute;
      right: 0.375rem;
      border-radius: 100%;
      background-color: ${props => props.theme.primary};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
      display: none;
    }

    &:hover {
      &:after {
        display: block;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.is-active {
      &:after {
        display: block;
      }
    }
  }

  ${props =>
    props.visible &&
    css`
      display: block;
    `}
`

export default ({ label, children }) => {
  const [open, setOpen] = useState(false)
  const labelButton = useRef(null)

  useEffect(() => {
    labelButton.current.addEventListener("click", () => {
      setOpen(!open)
    })
  })

  return (
    <Select>
      <Label ref={labelButton}>{label}</Label>
      <Options visible={open}>{children}</Options>
    </Select>
  )
}
