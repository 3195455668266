import React from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"

import logo from "../../images/logos/addmaya.svg"

const slideInRight = keyframes`
    from {
        transform: translate3d(-200%,0,0);
		opacity: 0;
    }
    to {
        transform: translate3d(0,0,0);
		opacity: 1;
    }
`

const Box = styled.figure`
	bottom: 2.5vw;
	width: 12.5vw;
	position: fixed;
	z-index: 20;
	
	a{
		width: 2.5rem;
		height: 10rem;
		margin-right: 1.125rem;
		float: right;
		background-image: url('${logo}');
		background-position: center bottom;
		display: block;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		animation: ${slideInRight} 1s cubic-bezier(0.62,-0.01, 0.25, 0.99) 1.25s both;
		animation-play-state: paused;
	}

	.page-has-loaded &  {
		a  {
			animation-play-state: running;
		}
	}

	@media screen and (max-width: 766px){
		bottom: 5vw; 

		a {
			margin-right: 0.65rem; 
		}
	}
`

export default () => {
  return (
    <Box>
      <Link to="/"></Link>
    </Box>
  )
}
