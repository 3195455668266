import React from "react"
import styled from "styled-components"
import Filter from "./filter"
import { Text } from "../shared/objects"

const Nav = styled.div`
  display: flex;
  border: solid 1px ${props => props.theme.tertiary};
  border-left: none;
  border-right: none;
  position: relative;
  z-index: 10;
`

export default () => (
  <Nav>
    <Filter label="Industry">
      <a href="/" className="is-active">
        <Text s14>Any</Text>
      </a>
      <a href="/">
        <Text s14>Health and Beauty</Text>
      </a>
      <a href="/">
        <Text s14>Finance</Text>
      </a>
      <a href="/">
        <Text s14>Political</Text>
      </a>
      <a href="/">
        <Text s14>Religion</Text>
      </a>
      <a href="/">
        <Text s14>Entertainment</Text>
      </a>
      <a href="/">
        <Text s14>Social Services</Text>
      </a>
    </Filter>

    <Filter label="Service">
      <a href="/" className="is-active">
        <Text s14>All</Text>
      </a>
      <a href="/">
        <Text s14>3D Animation</Text>
      </a>
      <a href="/">
        <Text s14>Filming</Text>
      </a>
      <a href="/">
        <Text s14>2D Animation</Text>
      </a>
      <a href="/">
        <Text s14>Logo Design</Text>
      </a>
      <a href="/">
        <Text s14>Service Design</Text>
      </a>
    </Filter>

    <Filter label="Client">
      <a href="/" className="is-active">
        <Text s14>All</Text>
      </a>
      <a href="/">
        <Text s14>UCC</Text>
      </a>
      <a href="/">
        <Text s14>Cafe Javas</Text>
      </a>
      <a href="/">
        <Text s14>XENO</Text>
      </a>
      <a href="/">
        <Text s14>MTN Uganda</Text>
      </a>
      <a href="/">
        <Text s14>Ministry of Defence</Text>
      </a>
    </Filter>
  </Nav>
)
