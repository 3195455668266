import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import Nav from '../menu/nav'
import Wrap from '../menu/wrap'
import Cursor from '../menu/cursor'

import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../../utilities/context"
import { Text } from "../shared/objects"


export default () => {
  const pointer = useRef(null)
  const nav = useRef(null)

  const dispatch = useGlobalDispatchContext()
  const { activeMenuItem, clickCount } = useGlobalStateContext()

  useEffect(() => {

    //set work menu item style to active on subsequent site clicks
    const pageLinks = document.querySelectorAll("a")
    if (clickCount === 0) {
      pageLinks.forEach(link => {
        link.addEventListener("click", () => {
          dispatch({
            type: "SET_CLICK_COUNT",
            clickCount: 1,
          })
        })
      })
    }

    // move cursor when mouse moves over menu item
    let y = 0
    const items = nav.current.querySelectorAll("a")
    const activeItem = document.querySelector(".is-active")

    function setCursorPosition(y) {
      if (pointer.current) {
        pointer.current.style.transform =
          "scale3d(1,1,1) translate3d(0," + y + "px, 0)"
      }
    }

    if (activeItem) {
      y = activeItem.offsetTop

      if (clickCount === 0) {
        setCursorPosition(y)
      }

      if (clickCount > 0 && y !== y) {
        setCursorPosition(y)
      }
    }

    items.forEach(function (item) {
      item.addEventListener("mouseover", function () {
        setCursorPosition(item.offsetTop)
      })

      item.addEventListener("mousedown", function () {
        setCursorPosition(item.offsetTop)
      })

      item.addEventListener("mouseout", function () {
        setCursorPosition(y)
      })
    })
  })

  return (
    <Nav ref={nav} className={activeMenuItem}>
      <Wrap>

        {activeMenuItem === 'home' &&
          <a href="#latest-projects" className={clickCount > 0 ? 'clear-delay is-active' : ''}>
            <Text s12 uppercase>
              Work
            </Text>
          </a>
        }

        {activeMenuItem !== 'home' &&
          <Link to="/" activeClassName="is-active" className={clickCount > 0 ? 'clear-delay' : ''}>
            <Text s12 uppercase>Work</Text>
          </Link>
        }

        <Link to="/agency/" activeClassName="is-active">
          <Text s12 uppercase>
            Agency
          </Text>
        </Link>

        <Link to="/team/" activeClassName="is-active">
          <Text s12 uppercase>
            Team
          </Text>
        </Link>

        <Link to="/service/kavuyo/" activeClassName="is-active">
          <Text s12 uppercase>
            Kavuyo
          </Text>
        </Link>

        <Cursor ref={pointer}></Cursor>
      </Wrap>
    </Nav>
  )
}
