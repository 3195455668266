import styled, { css, keyframes } from "styled-components"

export const Text = styled.span`
    
    ${props =>
      props.s104 &&
      css`
        font-size: 6.5rem;
      `}

    ${props =>
      props.s56 &&
      css`
        font-size: 3.5rem;
        letter-spacing: -1px;
      `}
      
    ${props =>
      props.s40 &&
      css`
        font-size: 2.5rem;
      `}

    ${props =>
      props.s36 &&
      css`
        font-size: 2.25rem;
        line-height: 1.2;
      `}
    
    ${props =>
      props.s18 &&
      css`
        font-size: 1.125rem;
      `}

    ${props =>
      props.s14 &&
      css`
        font-size: 0.875rem;
      `}

    ${props =>
      props.s12 &&
      css`
        font-size: 0.75rem;
      `}

    ${props =>
      props.uppercase &&
      css`
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
      `}
    
    ${props =>
      props.bold &&
      css`
        font-weight: bold;
      `}

    ${props =>
      props.block &&
      css`
        display: block;
      `}
`

export const FadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

export const PageContentWrap = styled.main`
  width: 62.5vw;
  margin-left: calc(12.5vw + 1px);
  padding-top: 5vw;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 1023px){
    width: 75vw;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    padding-left: calc(14vw + 1px);
  }
`


export const Picture = styled.figure`
  img {
    width: 100%;
    height: auto;
  }
`
