import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import Arrow from "../../images/icons/arrow-ltr-red.svg"

const List = styled.ul`
  padding-left: 25%;

  a {
    display: block;
    position: relative;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem; 
    transition: color 0.3s; 

    &:after{
      content:"";
      height: 1.25rem;
      width: 1.25rem;
      background-image: url('${Arrow}');
      background-repeat: no-repeat; 
      background-position:center; 
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0.5rem;
    }

    &:hover {
      color: ${props => props.theme.primary};
    }
  }

  li {
    padding: 1.5em 0;
    border-bottom: solid 1px ${props => props.theme.tertiary};
    width: 100%;
  }

  span {
    display: block;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    padding-top: 0.5rem;
  }

  ${props =>
    props.columns &&
    css`
      column-count: 2;
      column-gap: 0;
      line-height: 2.5;

      li {
        padding: 0;
        border: none;
      }
    `}

    @media screen and (max-width: 766px){
      padding-right: 2rem; 
      font-size: 0.875rem;

      a {
        padding-left: 2rem; 

        &:after{
          top: 0.45rem;
        }
      }
    }
`

export default ({ items, columns, type }) => (
  <List columns={columns}>
    {items.map((item, index) => (
      <li key={index}>
        {type !== "Services" && (
          <>
            {item.content}
            {item.byline && <span>{item.byline}</span>}
          </>
        )}

        {type === "Services" && (
          <Link to={item.slug ? "/service/" + item.slug : ""}>{item.content}</Link>
        )}
      </li>
    ))}
  </List>
)
