import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"
import { useGlobalDispatchContext } from "../utilities/context"
import { sanitizeString } from "../utilities/sanitize-string"

import Preface from "../components/slices/preface"
import Reels from "../components/slices/reels"
import List from "../components/slices/list"
import Mayans from "../components/slices/mayans"
import LatestProjects from "../components/slices/latest-projects"
import FeaturedServices from "../components/slices/featured-services"

import Section from "../components/shared/section"
import Video from "../components/shared/video"
import Slideshow from "../components/shared/slideshow"
import Moments from "../components/shared/moments"

export default ({ data }) => {
  const dispatch = useGlobalDispatchContext()
  const page = data.allPrismicPage.edges[0].node
  const content = page.data
  const title = content.title ? content.title.text : null
  const summary = content.summary ? content.summary.text : null
  const slices = content.body.length ? content.body : null

  let page_sections = []

  useEffect(() => {
    //show services submenu on work page
    if (page.uid === "work") {
      dispatch({
        type: "TOGGLE_SERVICES_MENU",
        showServices: "",
      })
    } else {
      dispatch({
        type: "TOGGLE_SERVICES_MENU",
        showServices: "is-hidden",
      })
    }

    //set active menu item
    let page_slug = page.uid !== "work" ? page.uid : "home"
    dispatch({
      type: "SET_ACTIVE_MENU",
      activeMenuItem: page_slug,
    })

    //populate page submenu
    if (page_sections) {
      if (page.uid !== "work") {
        dispatch({
          type: "SET_PAGE_SECTIONS",
          pageSections: page_sections,
        })
      } else {
        dispatch({
          type: "SET_PAGE_SECTIONS",
          pageSections: {},
        })
      }
    }
  }, [dispatch, page.uid, page_sections])

  return (
    <>
      <Meta
        title={title}
        image={{
          url: content.image ? content.image.url : null,
          height: content.image ? content.image.dimensions.height : null,
          width: content.image ? content.image.dimensions.width : null,
        }}
        description={summary}
        slug={page.uid}
      />

      <Preface
        layout={
          content.layout
            ? content.layout.toLowerCase().replace(/\s/g, "")
            : null
        }
        summary={summary}
        title={content.fancy_title.html ? content.fancy_title.html : null}
        buttonDefaultLabel={
          content.initial_label ? content.initial_label.text : null
        }
        buttonHoverLabel={content.hover_label ? content.hover_label.text : null}
        buttonVideo={content.hover_video ? content.hover_video.url : null}
        buttonAnchor={content.anchor ? content.anchor.text : null}
        post="Projects"
        page={page.uid}
        posts={content.media.length > 0 ? content.media : null}
        cover={content.image.fluid ? content.image.fluid.src : null}
      />

      {slices &&
        slices.map((slice, index) => {
          const content = slice.primary ? slice.primary : null

          switch (slice.slice_type) {
            //dynamic section
            case "section":
              const post = content.section_content
              const section_id = post
                ? sanitizeString(post) + "-" + index
                : null
              const section_title = content.section_title
                ? content.section_title.text
                : null

              if (post) {
                page_sections[index] = {
                  anchor: section_id,
                  label: section_title,
                }
              }

              switch (true) {
                //list
                case post === "Services" ||
                  post === "Board of Directors" ||
                  post === "Awards":
                  return (
                    <List
                      id={section_id}
                      key={index}
                      index={index}
                      title={section_title}
                      summary={
                        content.section_summary
                          ? content.section_summary.text
                          : null
                      }
                      post={post ? post : null}
                    />
                  )

                //milestones
                case post === "Milestones":
                  return (
                    <Slideshow
                      id={section_id}
                      key={index}
                      index={index}
                      title={section_title}
                      summary={
                        content.section_summary
                          ? content.section_summary.text
                          : null
                      }
                      post={post ? post : null}
                    />
                  )

                //moments
                case post === "Moments":
                  return <Moments key={index} />

                //team
                case post === "Team":
                  return <Mayans id={section_id} key={index} />

                //latest projects
                case post === "Latest Projects":
                  return <LatestProjects key={index} />

                //featured services
                case post === "Featured Services":
                  return <FeaturedServices key={index} />

                default:
                  break
              }
              break

            //reels
            case "reels":
              return <Reels key={index} reels={slice.items} />

            //video
            case "video":
              const video_url = content.video_file.url
              const video_poster = content.poster.fluid ? content.poster.fluid.src : null
              return (
                <Section key={index}>
                  <Video src={video_url} poster={video_poster} />
                </Section>
              )

            default:
              break
          }

          return null
        })}
    </>
  )
}

export const query = graphql`
  query PageQuery($uid: String) {
    allPrismicPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            title {
              text
            }
            summary {
              text
            }
            image {
              dimensions {
                width
                height
              }
              url
              fluid {
                src
              }
            }
            hover_label {
              text
            }
            hover_video {
              url
            }
            initial_label {
              text
            }
            anchor {
              text
            }
            layout
            media {
              post {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      photo {
                        fluid {
                          src
                        }
                      }
                      loop {
                        url
                      }
                    }
                  }
                }
              }
            }
            fancy_title {
              html
            }
            body {
              ... on PrismicPageBodySection {
                slice_type
                primary {
                  section_content
                  section_summary {
                    text
                  }
                  section_title {
                    text
                  }
                }
              }
              ... on PrismicPageBodyReels {
                slice_type
                items {
                  reel_video {
                    provider_name
                    embed_url
                  }
                  reel_title {
                    text
                  }
                  reel_loop {
                    url
                  }
                  reel_poster {
                    url
                  }
                }
              }
              ... on PrismicPageBodyVideo {
                slice_type
                primary {
                  video_file {
                    url
                  }
                  poster {
                    fluid {
                      src
                    }
                  }
                  video_title {
                    text
                  }
                }
              }
              ... on PrismicPageBodyFeaturedProjects {
                id
                slice_type
                items {
                  projects {
                    document {
                      ... on PrismicProject {
                        uid
                        data {
                          loop {
                            url
                          }
                          client {
                            project_client {
                              document {
                                ... on PrismicClient {
                                  id
                                  data {
                                    name {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageBodyFeaturedServices {
                slice_type
                items {
                  services {
                    document {
                      ... on PrismicService {
                        uid
                        data {
                          order
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`
