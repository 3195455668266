import React from "react"
import styled, { keyframes } from "styled-components"

const wipeIn = keyframes`
  0%{
    transform: none;
  }

  100% {
    transform: scale3d(0,1,1);
  }
`

const slideIn = keyframes`
  0%{
    opacity: 0; 
    transform: translate3d(10vw, 0, 0);
  }

  100% {
    transform: none;
    opacity: 1; 
  }
`

const Box = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  margin-top: 3vw;
  margin-bottom: 6.25vw;
  overflow: hidden;
  position: relative;
  left: 1px;
  background: ${props => props.theme.dim};

  &.clear-bottom {
    margin-bottom: 0;
  }

  .media-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    animation: ${slideIn} 1.25s cubic-bezier(0.22, 1, 0.36, 1) both;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 2;
      transform-origin: 0 0;
    }

    &.is-visible {
      display: block;

      &:before {
        animation: ${wipeIn} 0.65s ease-in-out both;
      }

      iframe,
      object,
      embed {
        display: block;
      }
    }
  }

  p {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #000;
    width: 55%;
    padding: 2rem;
  }

  .caption {
    display: block;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    line-height: 1.35;
    padding-top: 2rem;
  }

  .date {
    display: block;
    font-size: 0.75rem;
    width: 100%;
    text-align: right;
    color: ${props => props.theme.primary};
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  iframe,
  object,
  embed {
    display: none;
  }

  @media screen and (max-width: 766px) {
    &.photo-slide {
      padding-bottom: 0;
      height: auto;

      .media-wrap {
        position: relative;
      }
      p {
        position: relative;
        bottom: auto;
        width: 100%;
      }
    }
  }
`

export default ({ children, className }) => (
  <Box className={className}>{children}</Box>
)
