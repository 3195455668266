import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import Arrow from '../../images/icons/arrow-ltr-red.svg'

const Box = styled.section`
  margin-bottom: 10vw;

  opacity: 0;
  transform: translate3d(0, 25vh, 0) skewY(7deg);
  transition: transform 1s, opacity 1s;
  will-change: transform; 

  .is-visible & {
    opacity: 1;
    transform: none;
  }

  .cancel-scroll-animation & {
    opacity: 1 !important;
    transform: none !important;
  }

  header {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;

    i {
      position: absolute;
      left: 0.75rem;
      color: ${props => props.theme.primary};
    }

    ul,
    h1,
    h2,
    h3 {
      font-size: 1rem;
      font-weight: normal;
      width: 75%;
    }

    h1,
    h2 {
      border-bottom: solid 1px ${props => props.theme.tertiary};
      padding-bottom: 1rem;
      margin-bottom: 2.25rem;
    }

    h3 {
      padding-bottom: 1.5rem;
      position: relative;
    }

    p {
      width: 75%;
      padding-right: 15%;
      padding-bottom: 4.5vw;
    }

    h3:after
    {
      content: "";
      position: absolute;
      height: 1.125rem;
      width: 1.125rem;
      left: -12.5%;
      background-image: url('${Arrow}');
      transform: rotateZ(90deg);
      top: 0.75rem;
      background-repeat: no-repeat; 
    }

    p,
    li {
      line-height: 1.8;
    }

    li {
      padding-bottom: 0.625rem;
      position: relative;
      width: 100%;

      span {
        &:after {
          content: ", ";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 766px) {
    margin-bottom: 20vw;

    header {
      p {
        padding-right: 2rem;
        padding-bottom: 12vw;
      }
    }
  }
`

export default ({ id, children, className }) => {
  const [section, sectionInView, entry] = useInView({
    threshold: 0.25,
  })

  useEffect(() => {
    //animate-in on enter

    if (sectionInView) {
      entry.target.classList.add("is-visible")
    }

    //select corresponding submenu item
    if (id) {
      let submenuItem = document.querySelector("a[href='#" + id + "']")

      if (sectionInView && submenuItem) {
        submenuItem.classList.add("is-active")
      } else {
        if (submenuItem) {
          submenuItem.classList.remove("is-active")
        }
      }
    }
  }, [sectionInView])

  return (
    <section id={id} ref={section} className={className}>
      <Box>{children}</Box>
    </section>
  )
}
