import React from "react"
import styled, { keyframes } from "styled-components"
import Icon from "../../images/icons/arrow-rtl.svg"

const fadeInUp = keyframes`
    from {
        transform: translate3d(0, 2rem, 0); 
        opacity: 0; 
    }

    to {
        transform: none; 
        opacity: 1;
    }
`

const Box = styled.button`
  position: fixed;
  height: 3.5rem;
  width: 3.5rem;
  background-color: ${props => props.theme.primary};
  border-radius: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.75rem;
  left: calc(12.5vw + 0.75rem);
  animation: ${fadeInUp} 0.75s cubic-bezier(0.33, 1, 0.68, 1);

  @media screen and (max-width: 766px) {
    top: 10.5rem;
    left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }
`

const Arrow = styled.figure`
    background-image: url('${Icon}');
    width: 40%;
    height: 40%;
    background-repeat: no-repeat; 
    background-size: contain; 
    background-position: center; 
    transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
`

export default () => {
  return (
    <Box
      onClick={() => {
        window.history.back()
      }}
    >
      <Arrow />
    </Box>
  )
}
