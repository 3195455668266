import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Embed from "../../shared/embed"
import Icon from "../../../images/icons/play-button.svg"

const Box = styled.div`
    button {
        z-index: 10000;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center; 
        justify-content: center; 

        figure {
            height: 6rem;
            width: 6rem;
            background-color: ${props => props.theme.primary};
            border-radius: 100%; 
            background-image: url('${Icon}');
            background-position: 55% center; 
            background-repeat: no-repeat; 
            background-size: 40%;             
        }

        &.true {
            display: none;
        }
    }

    video,img {
        &.true {
            display: none;
        }

        &.false {
            display: block;
        }
    }

    &:hover{
        video, img {
            filter:grayscale(100%)
        }
    }
`

export default ({ poster, loop, url, visible }) => {
  let [play, setPlay] = useState(false)

  useEffect(() => {
    return () => {
      setPlay(false)
    }
  }, [visible])

  return (
    <Box className={"media-wrap " + visible}>
      {/* play button */}
      <button
        className={"" + play}
        onClick={() => {
          setPlay(true)
        }}
      >
        <figure></figure>
      </button>

      {/* loop */}
      <video className={"" + play} loop poster={poster} muted autoPlay>
        <source src={loop} type="video/mp4" />
      </video>

      {/* poster */}
      <img src={poster} className={"" + play} alt="" />

      {/* embed */}
      {visible && play && <Embed provider="Vimeo" url={url} autoplay={play} />}
    </Box>
  )
}
