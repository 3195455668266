import styled from "styled-components"

export default styled.section`
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 5vw;
  margin-bottom: 15vw;

  ul,
  p,
  .actions {
    width: 60%;
    border-bottom: solid 1px ${props => props.theme.tertiary};
    position: relative;
    transition: transform 0.9s, opacity 0.9s;
    opacity: 0;
    transform: translate3d(0, 4vh, 0) skewY(-5deg);
  }

  ul,
  p {
    padding: 3.5vw 15% 3.5vw 0;
  }

  p {
    line-height: 1.95;
    font-size: 1.125rem;
    transition-delay: 2.2s;
  }

  ul {
    column-count: 2;
    column-gap: 0;
    line-height: 2.3;
    transition-delay: 2.5s;

    li {
      opacity: 0.4;
      font-size: 0.8rem;
    }
  }

  .actions {
    transition-delay: 2.55s;
  }

  .fullcanvas & {
    margin-bottom: 0;
    width: 100%;

    p {
      width: 60%;
      padding-top: 0;

      &:before {
        top: 30%;
      }
    }
  }

  &.is-visible {
    .actions,
    ul,
    p {
      transform: none;
      opacity: 1;
    }

    strong {
      &:before {
        animation-play-state: running;
      }
    }
  }

  &.clear-delay {
    p {
      transition-delay: 0.85s;
    }

    ul {
      transition-delay: 1s;
    }

    .actions {
      transition-delay: 1.1s;
    }
  }

  @media screen and (max-width: 766px) {
    padding: 3rem 2rem 0;

    ul,
    p,
    .actions {
      width: 100%;
    }

    p {
      padding: 0;
      font-size: 1rem; 
      line-height: 2;
      padding-bottom: 5.5rem;
    }

    .actions {
      padding-top: 1rem;
    }
    .fullcanvas & {
      ul,
      p,
      .actions {
        width: 100%;
      }
    }
  }
`
