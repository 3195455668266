import React, { useState } from "react"
import { Text } from "../shared/objects"
import Nav from "./reels/nav"
import Reel from "./reels/thumb"
import Media from "../shared/media"

export default ({ reels }) => {
  const [activeReel, setActiveReel] = useState(0)

  return (
    <section id="reels">
      {/* reels */}
      <Media className="clear-bottom">
        {reels &&
          reels.map((item, index) => (
            <Reel
              key={index}
              poster={item.reel_poster.url}
              loop={item.reel_loop.url}
              url={item.reel_video.embed_url}
              visible={index === activeReel ? "is-visible" : null}
            />
          ))}
      </Media>

      {/* navigation */}
      <Nav>
        {reels &&
          reels.map(function (reel, index) {
            return (
              <button
                key={index}
                onClick={() => {
                  setActiveReel(index)
                }}
                className={index === activeReel ? "is-active" : null}
              >
                {reel.reel_title.text && (
                  <Text uppercase s12>
                    {reel.reel_title.text} Reel
                  </Text>
                )}
              </button>
            )
          })}
      </Nav>
    </section>
  )
}
