import { keyframes } from "styled-components"

export const easings = {
  easeOutCubic: "cubic-bezier(0.33, 1, 0.68, 1)",
  easeInOutCubic: "cubic-bezier(0.65, 0, 0.35, 1)",
  easeInOutQuad: "cubic-bezier(0.45, 0, 0.55, 1)",
}

export const FadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

export const slideInRight = keyframes`
    from {
        transform: translate3d(-100%,0,0);
    }
    to {
        transform: translate3d(0,0,0);
    }
`

export const fadeInBottom = keyframes`
    from {
        opacity: 0; 
        transform: translate3d(0,100%,0);
    }
    to {
        opacity: 1; 
        transform: translate3d(0,0,0);
    }
`

export const Blink = keyframes`
    
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
`
