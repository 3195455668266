import styled, {keyframes} from 'styled-components'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export default styled.span`
  position: absolute;
  right: 0px;
  height: 1.875rem;
  width: 2px;
  background-color: ${props => props.theme.primary};
  display: block;
  z-index: 1;
  transition: transform 0.5s;
  transform-origin: 100% 0;
  opacity: 1;
  z-index: 1000;
  top: 0.25rem;

  animation: ${fadeIn} 0.5s ease 2s both;
  animation-play-state: paused;

  .page-has-loaded & {
    opacity: 1;
    animation-play-state: running;
  }

  @media screen and (max-width: 766px) {
    display: none;
  }
`