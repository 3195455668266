import React, { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import { Text } from "../shared/objects"
import { renderClients } from "../../utilities/render-project-clients"
import { useGlobalDispatchContext } from "../../utilities/context"

const Meta = styled.div`
  flex: 1;
  padding: 1.125rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    height: 1px;
    width: 35%;
    background-color: ${props => props.theme.tertiary};
  }

  &:before {
    bottom: 0;
  }

  &:after {
    top: 0;
  }

  div {
    width: 100%;
  }

  h2 {
    padding-bottom: 0.45rem;
  }

  @media screen and (max-width: 766px) {
    h2 {
      padding-bottom:0.625rem;

      span {
        font-size: 1.25rem;
      }
    }
  }
`
const Media = styled.div`
  flex: 2;
  display: flex;
  padding-bottom: 36.05%;
  position: relative;
  overflow: hidden;

  figure {
    width: 100%;
    height: 100%;
  }

  video {
    position: absolute;
    z-index: 1;
    opacity: 1;
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.65s cubic-bezier(0.22, 1, 0.36, 1);
  }
`

const Thumb = styled.figure`
  background-color: ${props => props.theme.dim};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  overflow: hidden;

  img {
    opacity: 0;

    &.is-visible {
      opacity: 1;
    }
  }
`

const MetaItem = styled.span`
  position: absolute;
  bottom: 1.125rem;
  font-size: 0.625rem;
  text-transform: uppercase;
  opacity: 1;
  color: ${props => props.theme.grey};
  left: 1.125rem;
  letter-spacing: 1px;
  font-weight: bold;

  ${props =>
    props.right &&
    css`
      left: auto;
      right: 1.125rem;
    `}

  ${props =>
    props.static &&
    css`
      position: static;
    `}

    @media screen and (max-width: 766px) {
    font-size: 0.5rem;
    line-height: 1.5;
    display: block;
  }
`

const Item = styled.article`
  position: relative;
  padding-bottom: 12vw;
  width: 100%;
  transition: transform 0.75s, opacity 1s;
  transition-delay: 0.25s;
  transform-origin: 0 0;
  will-change: transform; 

  a {
    display: flex;
    position: relative;

    &:hover {
      .media {
        opacity: 0.75;
        filter: grayscale(100%);
      }

      img,
      video {
        transform: scale3d(1.05, 1.05, 1.05);
      }
    }
  }

  &.is-hidden {
    opacity: 0;
    transform: translate3d(0, 2rem, 0) skewY(7deg);
  }

  &.is-visible {
    opacity: 1;
    transform: none;
  }
`

export default ({ index, slug, thumb, client, title, service, loop }) => {
  const [box, boxInView] = useInView({
    triggerOnce: true,
  })
  const [visible, setVisible] = useState("is-hidden")
  const dispatch = useGlobalDispatchContext()

  const [thumbEle, thumbEleInView] = useInView()
  const image = useRef()
  const [imageView, setImageView] = useState("is-hidden")

  const videoEle = useRef()
  const linkEle = useRef()



  useEffect(() => {
    //animate thumb when in view
    boxInView ? setVisible("is-visible") : setVisible("is-hidden")

    //play videos when in view
    if (loop) {
      if (thumbEleInView) {
        videoEle.current.play()
      } else {
        videoEle.current.pause()
      }
    }

    //fade in thumb image when loaded
    if (image.current.complete) {
      setImageView("is-visible")
    }
  })

  //show custom curson on mouse enter on media thumb
  const onMouseEnter = () => {
    dispatch({
      type: "SHOW_CUSTOM_CURSOR",
      showCursor: true,
    })
  }

  const onMouseLeave = () => {
    dispatch({
      type: "SHOW_CUSTOM_CURSOR",
      showCursor: false,
    })
  }

  useEffect(() => {
    function isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }

    if (!isTouchDevice()) {
      linkEle.current.addEventListener("mouseenter", onMouseEnter)
      linkEle.current.addEventListener("mouseleave", onMouseLeave)
      linkEle.current.addEventListener("click", onMouseLeave)
    }


    return () => {
      if (!isTouchDevice()) {
        linkEle.current.removeEventListener("mouseenter", onMouseEnter)
        linkEle.current.removeEventListener("mouseleave", onMouseLeave)
        linkEle.current.removeEventListener("click", onMouseLeave)
      }
    }
  }, [])

  return (
    <Item className={"thumb " + visible} ref={box}>
      <Link to={"/project/" + slug} className="link" ref={linkEle}>
        {/* <Arrow className="arrow"/> */}

        <Media className="media">
          {loop && (
            <video ref={videoEle} loop muted playsinline>
              <source src={loop} type="video/mp4" />
            </video>
          )}

          <Thumb ref={thumbEle}>
            <img
              className={imageView}
              ref={image}
              src={thumb}
              alt={title + " - " + renderClients(client).join(", ")}
            />
          </Thumb>
        </Media>

        <Meta className="meta">
          <div>
            <h2>
              <Text s36 bold>
                {title}
              </Text>
            </h2>
            {client && (
              <MetaItem static>{renderClients(client).join(", ")}</MetaItem>
            )}
          </div>

          {service.document && (
            <MetaItem className="category">
              {service.document.data.title.text}
            </MetaItem>
          )}

          <MetaItem right>0{index}</MetaItem>
        </Meta>
      </Link>
    </Item>
  )
}
