import React from "react"
import styled, { keyframes } from "styled-components"
import { useGlobalStateContext } from "../../../utilities/context"

let slideIn = keyframes`
    from {
        transform: translate3d(-10vw, 0, 0) skewY(7deg) scale3d(1.2,1,1);
        opacity: 0;
    }

    to {
        transform: none;
        opacity: 1;
    }
`

let delay = 2.45
let ratio = 0.5625

const Box = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.75;

  &.clear-delay {
    figure {
      animation-delay: 0.75s !important;
    }
  }

  figure {
    position: absolute;
    width: 14%;
    padding-bottom: ${14 * Math.pow(ratio, -1)}%;
    transform-origin: 100% 0;
    animation: ${slideIn} 3s cubic-bezier(0.16, 1, 0.3, 1) both;
    animation-play-state: paused;

    .page-has-loaded & {
      animation-play-state: running;
    }

    &:nth-child(1) {
      animation-delay: ${delay + 0.25}s;
    }

    &:nth-child(2) {
      animation-delay: ${delay}s;
    }

    &:nth-child(3) {
      animation-delay: ${delay + 0.15}s;
    }

    &:nth-child(4) {
      animation-delay: ${delay + 0.1}s;
    }

    &:nth-child(5) {
      bottom: 0%;
      left: 76%;
      width: 14%;
      padding-bottom: ${2 * Math.pow(ratio, -1)}%;
      animation-delay: ${delay + 0.1}s;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      filter:grayscale(100%);
    }

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &.grid-a {
    figure {
      &:nth-child(1) {
        bottom: 65%;
        left: 0;
      }

      &:nth-child(2) {
        bottom: 0%;
        left: 16%;
      }

      &:nth-child(3) {
        bottom: 85%;
        left: 70%;
      }

      &:nth-child(4) {
        bottom: 40%;
        left: 100%;
      }
    }
  }

  &.grid-b {
    figure {
      &:nth-child(1) {
        bottom: 50%;
        left: 40%;
      }

      &:nth-child(2) {
        bottom: 70%;
        left: 0%;
      }

      &:nth-child(3) {
        bottom: 80%;
        left: 86%;
      }

      &:nth-child(4) {
        bottom: 0%;
        left: 16%;
      }
    }
  }

  &.grid-c {
    figure {
      &:nth-child(1) {
        bottom: 0%;
        left: 86%;
      }

      &:nth-child(2) {
        bottom: 50%;
        left: 15%;
      }

      &:nth-child(3) {
        bottom: 80%;
        left: 45%;
      }

      &:nth-child(4) {
        bottom: 12.5%;
        left: 0%;
      }
    }
  }

  &.grid-d {
    figure {
      &:nth-child(1) {
        bottom: 5%;
        left: 50%;
      }

      &:nth-child(2) {
        bottom: 45%;
        left: 0%;
      }

      &:nth-child(3) {
        bottom: 55%;
        left: 86%;
      }

      &:nth-child(4) {
        bottom: 80%;
        left: 26%;
      }
    }
  }

  &.grid-e {
    figure {
      &:nth-child(1) {
        bottom: 60%;
        left: 0;
      }

      &:nth-child(2) {
        bottom: 5%;
        left: 26%;
      }

      &:nth-child(3) {
        bottom: 80%;
        left: 86%;
      }

      &:nth-child(4) {
        bottom: 60%;
        left: 72%;
      }
    }
  }

  @media screen and (max-width: 766px) {
    figure div {
      opacity: 0.2; 
    }
  }
`

export default ({ children, className }) => {
  const { clickCount } = useGlobalStateContext()

  return (
    <Box
      className={`preface-image ${className} ${
        clickCount > 0 ? "clear-delay" : ""
      }`}
    >
      {children}
    </Box>
  )
}
