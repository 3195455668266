import React from "react"

import Section from "../shared/section"
import Embed from "../shared/embed"
import Header from "./header"

export default ({ meta, items }) => {
  const title = meta.video_album_title ? meta.video_album_title.text : ""
  const summary = meta.video_album_summary ? meta.video_album_summary.text : ""

  return (
    <Section>
      <Header title={title} excerpt={summary} />

      {items.map((item, index) => (
        <Embed
          key={index}
          url={item.video.embed_url}
          provider={item.video.provider_name}
          title={title}
        />
      ))}
    </Section>
  )
}
