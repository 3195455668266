import React from "react"

import { renderClients } from "../../utilities/render-project-clients"
import { renderServices } from "../../utilities/render-project-services"

import Section from "../shared/section"
import { Text } from "../shared/objects"

export default ({ title, date, clients, services }) => {
  return (
    <Section lead>
      <header>
        <i>
          <Text s14>00</Text>
        </i>

        <h1>
          <Text uppercase s14>
            {title}
          </Text>
        </h1>

        <ul>
          {clients && (
            <li>
              <Text>{renderClients(clients).join(", ")}</Text>
            </li>
          )}

          {services && (
            <li>
              <Text>{renderServices(services).join(", ")}</Text>
            </li>
          )}

          {date && <li>{date.substring(0, 4)}</li>}
        </ul>
      </header>
    </Section>
  )
}
