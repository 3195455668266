import React from "react"
import { Text } from "../shared/objects"

export default ({ title, excerpt }) => (
  <header>
    <h3>
      <Text bold>{title}</Text>
    </h3>
    <p>{excerpt}</p>
  </header>
)
