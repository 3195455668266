import { useStaticQuery, graphql } from "gatsby"

export const useLatestProjectsData = () => {
  const data = useStaticQuery(
    graphql`
      query FeaturedProjects {
        allPrismicProject(
          limit: 10, 
          filter: {data: {primary_service: {uid: {ne: "kavuyo"}}}},
          sort: { fields: data___date, order: DESC }
        ) {
          edges {
            node {
              uid
              data {
                primary_service {
                  document {
                    ... on PrismicService {
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
                client {
                  project_client {
                    document {
                      ... on PrismicClient {
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                title {
                  text
                }
                photo {
                  url
                  fluid {
                    src
                  }
                }
                loop {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicProject.edges
}
