export function renderServices(services) {
  let items = []

  if (services) {
    services.map((item, index) => {
      if (item.service) {
        let document = item.service.document

        if (document && document.data.title) {
          items[index] = document.data.title.text
        }
      }

      return null
    })
  }

  return items
}
