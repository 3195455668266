import React from "react"

import { useEffect } from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"
import { useGlobalDispatchContext } from "../utilities/context"

import Hero from "../components/project/hero"
import Summary from "../components/project/summary"
import Approach from "../components/project/approach"
import List from "../components/slices/list"
import BackButton from "../components/shared/back-button"

export default ({ data }) => {
  const project = data ? data.allPrismicProject.edges[0].node.data : null

  const title = project.title ? project.title.text : null

  const photo_url = project.photo ? project.photo.url : null
  const photo_height = project.photo ? project.photo.dimensions.height : null
  const photo_width = project.photo ? project.photo.dimensions.width : null
  const summary = project.project_summary.text
    ? project.project_summary.text
    : null

  const video_url = project.video ? project.video.embed_url : null
  const video_provider = project.video ? project.video.provider_name : null

  const date = project.date ? project.date : null
  const clients = project.client ? project.client : null
  const services = project.services ? project.services : null

  const work = project.project_content ? project.project_content : null
  const credits = project.credits.length ? project.credits : null

  const dispatch = useGlobalDispatchContext()

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_MENU",
      activeMenuItem: "project",
    })
  })

  return (
    <>
      <Meta
        title={title}
        image={{
          url: photo_url,
          width: photo_width,
          height: photo_height,
        }}
        description={summary}
      />
      <BackButton />

      <Hero
        url={video_url}
        provider={video_provider}
        title={title}
        poster={project.photo.fluid ? project.photo.fluid.src : photo_url}
      />

      <Summary
        title={title}
        date={date}
        clients={clients}
        services={services}
      />

      {work && (
        <Approach index="01" title="Approach" summary={summary} slices={work} />
      )}

      {credits && credits[0].name.text && (
        <List title="Credits" index="02" credits={credits} />
      )}
    </>
  )
}

export const query = graphql`
  query ProjectQuery($uid: String) {
    allPrismicProject(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            client {
              project_client {
                document {
                  ... on PrismicClient {
                    id
                    uid
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
            }
            credits {
              name {
                text
              }
              role {
                text
              }
            }
            services {
              service {
                document {
                  ... on PrismicService {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            title {
              text
            }
            video {
              embed_url
              provider_name
            }
            date
            loop {
              url
            }
            photo {
              url
              fluid {
                src
              }
              dimensions {
                height
                width
              }
            }
            project_summary {
              text
            }
            project_content {
              ... on PrismicProjectProjectContentPhotos {
                id
                items {
                  photo {
                    url
                    fluid {
                      src
                    }
                  }
                }
                slice_type
                primary {
                  photo_album_layout
                  photo_album_summary {
                    text
                  }
                  photo_album_title {
                    text
                  }
                }
              }
              ... on PrismicProjectProjectContentVideos {
                items {
                  video {
                    embed_url
                    provider_name
                  }
                }
                slice_type
                primary {
                  video_album_title {
                    text
                  }
                  video_album_summary {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
