// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fantasy-premiere-league-js": () => import("./../src/pages/fantasy-premiere-league.js" /* webpackChunkName: "component---src-pages-fantasy-premiere-league-js" */),
  "component---src-pages-museum-js": () => import("./../src/pages/museum.js" /* webpackChunkName: "component---src-pages-museum-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-js": () => import("./../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

