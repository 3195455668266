import React from "react"
import styled from "styled-components"
import Arrow from "../../images/utility/next.svg"
import { Text } from "./objects"

const Box = styled.div`
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 0.75rem;
  overflow: hidden;

  h3,
  p {
    display: none;
  }

  span {
    display: block;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 766px) {
    div span {
      font-size: 1.5rem;
      padding-bottom: 0.25rem;
      padding-top: 0.625rem;
    }

    .title {
      font-size: 0.875rem;
    }
  }
`

const Wrap = styled.button`
  float: right;
  display: block;
  color: inherit;
  text-align: left;
  width: calc(75% - 0.75rem);
  padding: 0.5rem 0 1.5rem;
  border-bottom: solid 1px ${props => props.theme.tertiary};
  position: relative;
  margin-left: 1.5rem;
  transition: color 0.5s;

  &:before{
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 4.5rem;
    top: 2rem;
    background-image: url('${Arrow}');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;
    content: "";
  }

  img {
    object-fit: cover; 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    filter: grayscale(100%);
    transition: opacity 0.5s, transform 0.3s ease-in-out; 
  }

  &:hover {
    color: ${props => props.theme.primary};

    img {
      opacity: 0.25;
      transform: translate3d(-1rem, 0, 0);
    }
  }

  @media screen and (max-width: 766px){
    &:before {
      top: 1.5rem; 
    }
  }
`
const Index = styled.span`
  position: absolute;
  left: -3rem;
  top: 1.5rem;
`

export default ({
  name,
  title,
  photo,
  index,
  nickname,
  mouseEnter,
  mouseLeave,
}) => {
  return (
    <Box>
      <Wrap onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div>
          <Text s40 bold>
            {nickname}
          </Text>
        </div>
        <span className="title">{title}</span>
        <Index>
          <Text s12>0{index}</Text>
        </Index>
        <img src={photo} alt={name} />
      </Wrap>
    </Box>
  )
}
