import styled, { keyframes } from "styled-components"

const wipeIn = keyframes`
  0%{
    transform: scale3d(1,1,1);
  }

  100% {
    transform: scale3d(0,1,1);
  }
`

const slideIn = keyframes`
  0%{
    opacity: 0; 
    transform: translate3d(10vw, 0, 0);
  }

  100% {
    transform: none;
    opacity: 1; 
  }
`

export default styled.div`
  position: fixed;
  bottom: 0;
  width: 40vw;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 85vh;
  z-index: 20000;
  pointer-events: none;
  display: none;
  overflow: hidden;

  h2 {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    z-index: 200;
    color: #fff;
  }

  .title {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    display: block;
    font-size: 0.875rem;
  }

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 100;
      transform-origin: 100% 0;
      transform: scale3d(1, 1, 1);
    }

    &.is-visible {
      display: block;
      animation: ${slideIn} 1.25s cubic-bezier(0.22, 1, 0.36, 1) both;

      &:before {
        animation: ${wipeIn} 0.35s ease-in-out both;
      }
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.is-visible {
    display: block;
  }

  @media screen and (max-width: 766px) {
    left: 14vw; 
    width:calc(100% - 14vw); 
    height: 50vh; 
  }
`
