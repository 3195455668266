import { useStaticQuery, graphql } from "gatsby"

export const useAwardsData = () => {
  const data = useStaticQuery(
    graphql`
      query AwardsQuery {
        allPrismicAward {
          edges {
            node {
              data {
                date
                title {
                  text
                }
                award {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicAward.edges
}
