import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

import { useGlobalDispatchContext } from "../../utilities/context"
import { useLatestProjectsData } from "../../utilities/use-latest-projects-data"

import Section from "../shared/section"
import ProjectsGrid from "../shared/projects-grid"
import ProjectThumb from "../project/thumbnail"

export default () => {
  const projects = useLatestProjectsData() ? useLatestProjectsData() : null

  const [wrap, wrapInView] = useInView()
  const dispatch = useGlobalDispatchContext()
  const [gridClass, setGridClass] = useState('grid-1-2')

  useEffect(() => {
    if (wrapInView) {
      dispatch({
        type: "set_active_service",
        activeService: 1,
      })
    } else {
      dispatch({
        type: "set_active_service",
        activeService: 0,
      })
    }
  }, [wrapInView, dispatch])

  // set mobile grid
  useEffect ( () => {
    if(window.innerWidth < 766){
      setGridClass('grid-1')
    }
  }, [])

  return (
    <Section id="latest-projects" className="cancel-scroll-animation">
      <div ref={wrap}>
        <ProjectsGrid className={gridClass}>
          {projects.map((item, index) => (
            <ProjectThumb
              slug={item.node.uid}
              key={index}
              thumb={item.node.data.photo.fluid.src}
              loop={item.node.data.loop.url}
              title={item.node.data.title.text}
              service={item.node.data.primary_service}
              client={item.node.data.client}
              index={index}
            />
          ))}
        </ProjectsGrid>
      </div>
    </Section>
  )
}
