import React from "react"
import { useRef, useEffect } from "react"
import styled from "styled-components"
import arrow from "../../images/utility/next.svg"
import arrowBlack from "../../images/utility/next-black.svg"

const Box = styled.div`
    border-top: solid 1px ${props => props.theme.tertiary};

    a {
        text-transform: lowercase;
        display: block;
        padding: 2.75rem 0;
        padding-left: 4.5rem;
        position: relative;
        overflow: hidden;

        div {
            overflow: hidden;
            position: relative;

            span {
                display: block;

                &:last-child {
                    transform: translate3d(0, 100%, 0);
                    color: ${props => props.theme.base};
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
            }
        }

        video, &:after, div span, figure, img {
            transition-property: transform;
            transition-duration: 0.75s;
            transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            will-change: transform; 
        }

        figure {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            right: 4.5rem;
            top: 4.125rem;
            background-image: url('${arrow}');
            background-repeat: no-repeat;
            background-size: contain;
        }

        video, img {
            width: 100%; 
            height: 100%;
            object-fit: cover; 
            filter:grayscale(100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0.5;
            transform: scale3d(1,0,1);
            transform-origin: 0 75%; 
        }

        img {
          z-index: -2;
        }
        
        &:after{
            content: "";
            background: ${props => props.theme.primary};
            transform-origin: 0 100%; 
            height: 100%; 
            width: 100%;
            position: absolute;
            z-index: -1; 
            transform: scale3d(1,0,1);
            mix-blend-mode: multiply;
            left: 0;
            top: 0;
            transition-delay: 0.125s;
        }
    }
    
    &.is-active a, a:hover {
        video, &:after, img{
            transform: scale3d(1,1,1);
        }

        figure {
            background-image: url('${arrowBlack}');
        }

        div {
            span {
                &:first-child{
                    transform: translate3d(0, -100%, 0);
                }

                &:last-child {
                    transform: translate3d(0,0,0);
                }
            }
        }
    }

    @media screen and (max-width: 766px){
      a {
        padding: 2rem 0; 
        padding-left: 4rem; 
        
        figure {
          top: 2.5rem; 
        }

        div {
          span {
              font-size: 1.87rem;
          }
        }
      }
    }

`

export default ({ children, state }) => {
  const box = useRef(null)

  useEffect(() => {
    let button = box.current.querySelector("a")
    let video = button.querySelector("video")

    if (video && button) {
      button.addEventListener(
        "mouseover",
        () => {
          if (video.readyState === 4) {
            video.play()
          }
        },
        false
      )

      button.addEventListener(
        "mouseleave",
        () => {
          if (video.readyState === 4) {
            video.pause()
          }
        },
        false
      )
    }
  }, [])

  return (
    <Box className={state} ref={box}>
      {children}
    </Box>
  )
}
