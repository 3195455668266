import { useStaticQuery, graphql } from "gatsby"

export const useServicesData = () => {
  const data = useStaticQuery(
    graphql`
      query AllServicesQuery {
        allPrismicService(
          sort: { fields: data___order, order: ASC }
          filter: { data: { featured: { eq: true }, order: { gt: 0 } } }
        ) {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicService.edges
}
