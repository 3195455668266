import { useStaticQuery, graphql } from "gatsby"

export const useMilestonesData = () => {
  const data = useStaticQuery(
    graphql`
      query MilestonesQuery {
        allPrismicMilestone(sort: { fields: data___date, order: ASC }) {
          edges {
            node {
              data {
                date(fromNow: true)
                photo {
                  url
                }
                summary {
                  text
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allPrismicMilestone.edges
}
